
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.core();


$bcipotterydirect-frontend-angular-primary: mat.define-palette(mat.$indigo-palette);
$bcipotterydirect-frontend-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bcipotterydirect-frontend-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bcipotterydirect-frontend-angular-theme: mat.define-light-theme((
  color: (
    primary: $bcipotterydirect-frontend-angular-primary,
    accent: $bcipotterydirect-frontend-angular-accent,
    warn: $bcipotterydirect-frontend-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bcipotterydirect-frontend-angular-theme);

/* You can add global styles to this file, and also import other style files */
@import './assets/styles/variable.scss';
@import './assets/styles/font-face.scss';
@import './assets/styles/responsive.scss';

html, body { height: 100%; }
body { margin: 0; font-family: "Poppins", sans-serif;}
p{padding: 0; margin: 0;}
a{text-decoration: none;}
/* custom universal scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* custom universal scrollbar */
/* customer colloum */
.cmn-row{@include common-row}
.col-full{@include col-four}
.col-75{ @include col-three}
.col-50{ @include col-tow}
.col-25{ @include col-one}
.col-33{ @include col-single}
.col-65{ @include col-one-thrd}
.col-35{ @include col-thrd-one}
/* customer colloum */

.container-xl{@include center-container;}
.container-fluid{@include full-container;}

/* ///  HEADER /// */

/* top-header */
.top-gray-header{ padding:0 20px; margin: 0; width: 100%; height:35px; display: flex; justify-content: flex-end; align-items: center;
  background-color: $primary-color-2; position: fixed; top:0; z-index: 99; box-sizing: border-box;
  ul{padding: 0; margin: 0; list-style: none; display: flex; align-items: center; justify-content: flex-end;
      li{padding:0 0 0 40px; margin: 0; color: #ffffff; font-family: $projet-common-font; font-size: 14px; font-weight: 300;
        a{text-decoration: none; color: #ffffff;}
      }
  }
}
/* top-header */
/* main header */
.main-header{ padding:5px 0 0 0; margin:35px 0 0 0; width: 100%; z-index: 99;
  .header-inner-sec{padding:0 20PX; margin: 0 auto; width:100%; display: flex; align-items: center; justify-content: space-between; box-sizing: border-box;

    a.mobile-toggle{ width: 40px; height: 40px; margin-right: 30px; display: none;}
    a.back-arrow{  width:30px; height:30px; margin-right: 30px; display: none; align-items: center; justify-content: center;
      i{font-size:26px;}
    }
    a.main-logo{ padding: 0; margin: 0; width: 140px; height: auto; border: 0; outline: none; display: inline-block;
      img{width: 100%; height: auto;}
    }
    .header-heading{ padding: 0; margin: 0; display: inline-flex; flex-direction: column; justify-content: center;
      p{ padding: 0; margin: 0; display: block; font-size:24px; color:$primary-color; font-family:$projet-common-font; font-weight: 600; text-align: center; letter-spacing: 3px; }
      span{padding: 0; margin: 0; display: inline-block; font-size:16px; color:$primary-color; font-family:$projet-common-font; font-weight: 500; text-align: center; letter-spacing: 3px;}
    }
    .header-left-sec{ padding: 0; margin: 0; display: inline-flex;
        ul{ padding: 0; margin: 0; list-style: none; display: flex;
          li{padding:0; margin:0 0 0 20px; font-size: 22px; position: relative;
            a{ padding:0 0 0 5px; margin: 0; text-decoration: none; box-sizing: border-box; color: $primary-color;}
            .login-drop-box{ padding:20px; margin: 0; width: 220px; height:auto; position: absolute; top:30px; right: 0; background-color: #ffffff; z-index: 2;
              box-sizing: border-box; -moz-box-sizing: border-box;
              -webkit-box-shadow: 0px 10px 23px -19px rgba(0,0,0,0.77);
              -moz-box-shadow: 0px 10px 23px -19px rgba(0,0,0,0.77);
              box-shadow: 0px 10px 23px -19px rgba(0,0,0,0.77); display: none;

              ul{ padding: 0; margin: 0; list-style: none; width: 100%; display: flex; flex-direction: row; flex-direction: column;
                li{ padding:0; margin: 0; display: flex; width: 100%;
                  a{padding:0 0 12px 0; margin: 0; @include common-bdy-font; cursor: pointer; font-size: 14px; width: 100%; display: flex; align-items: center;
                    i{ padding-right: 12px; font-size: 14px;}
                  }
                  a:hover{color: #174E6D;}
                }
              }
            }
            .login-drop-box.show{display: flex;}
          }
        }
    }
  }
  .mega-menu{ padding:15px 0 0; margin:0; width:100%; position: relative; background-color: #fff;
    .menu-tab-iner-wrap{ margin: 0 auto; width: 1325px; display: flex; align-items: center; justify-content:center; padding-left: 148px; padding-right: 200px; box-sizing: border-box;
                        .menu-tab{ padding:10px 0; margin:0 15px; list-style:none; @include common-bdy-font; cursor: pointer; border-bottom: #fff 4px solid; font-weight: 400; background-color: #fff; }
                        .menu-tab:hover, .menu-tab.active{border-bottom: $primary-color 4px solid; color:$primary-color; font-weight: 600; margin:0 13.10px; }
                        .mega-menu-box{ padding:30px 45px; margin: 0; position:absolute; width: 100%; background-color: #fff; height:auto;
                        opacity:0;
                        z-index: 999 !important;
                         border-top: #f6efef 1px solid;
                         -webkit-box-shadow: -6px 7px 41px -26px rgba(0,0,0,0.77);
                          -moz-box-shadow: -6px 7px 41px -26px rgba(0,0,0,0.77);
                          box-shadow: -6px 7px 41px -26px rgba(0,0,0,0.77);
                          transition: opacity .25s ease-in-out;
                          -moz-transition: opacity .25s ease-in-out;
                          -webkit-transition: opacity .25s ease-in-out;
                          box-sizing: border-box; -moz-box-sizing: border-box; pointer-events: all; z-index:10; display: none;
                          top: 62px;
                          left: 0;
                            right: 0;
                          .mega-innerwrap{ margin: 0 auto; width: 1325px; display: flex; align-items:flex-start;
                            .left-section{ padding:0 80px 0 0; margin: 0; width: calc(100% - 400px); box-sizing: border-box; display: flex; justify-content: space-between;
                               ul:first-child{padding-right:70px; border-right: #ccc 1px solid; box-sizing: border-box; width: 300px; height: 400px;}
                               ul:nth-child(2){margin-right: auto;padding-left: 30px;}
                              ul{ padding: 0 50px 0 0;
                                margin: 0;
                                display: inline-flex;
                                list-style: none;
                                flex-direction: column;
                                box-sizing: border-box;
                                  li:first-child{ @include common-bdy-font; color: $primary-color; font-size: 16px; font-weight: 600;}
                                  li{ padding:0 0 12px 0; margin: 0; display: inline-flex;
                                    a{ cursor: pointer; @include common-bdy-font;}
                                    a:hover{color: $primary-color;}
                                    a.active{font-weight: 600;}
                                  }
                              }
                            }
                            .right-image-block{padding: 0;
                              margin: 5px;
                              width: 300px;
                              height: 300px;
                              min-width: 300px;
                              overflow: hidden;
                              box-sizing: content-box; display: flex; align-items: center; justify-content: center; position: relative;
                              .text{ position: absolute; @include heading-36; left:0; right: 0; display: flex; justify-content: center; color: #fff;
                                font-weight: 600;}
                              img{
                                box-sizing: border-box;
                                height: 100%;
                                object-fit: fill;
                                width: 100%;
                                }
                            }
                          }


                        }
                        .mega-menu-box.show{ display: flex; opacity:1;}

                      }
                    }
}

/* mega search */
.search{ padding: 0; margin: 0; display: inline-block;

}
.mega-search{ padding:20px 45px 30px; margin: 0; position: fixed; width: 100%; background-color: #fff; top:35px;
  opacity:1;
  z-index: 4;
  left:0; right: 0; height:500px;
   border-top: #f6efef 1px solid;
    -webkit-box-shadow: -1px 0px 33px -1px rgb(0 0 0 / 8%);
    -moz-box-shadow: -1px 0px 33px -1px rgb(0 0 0 / 8%);
    box-shadow: -1px 0px 33px -1px rgb(0 0 0 / 8%);
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    box-sizing: border-box; -moz-box-sizing: border-box;
    .mega-search-innerwrap{ margin: 0 auto; width: 600px; display: flex; align-items:flex-start; flex-direction: column;
      .input-outerbox{ padding:6px 100px 6px 50px; width: 100%; border: #ccc 1px solid;  box-sizing: border-box; -webkit-box-sizing: border-box; position: relative; display: flex; align-items: center; justify-content: center;
        .search-con{ padding: 0; margin: 0; position: absolute; left:15px; width: 30px; color:#174E6D;
            i{font-weight: 28px;  }
        }
        button{ padding:7px 12px 7px 14px; margin: 0; background-color:#EEECE9; color: 13px; color: #333333; box-sizing: border-box; display: inline-block; border: 0;
          outline: none; cursor: pointer; font-size: 13px; position: absolute; right:10px;
          i{padding: 0 0 0 10px; color: #333333; font-size: 10px;}
        }
        input{ padding: 0; margin: 0; width: 100%; background-color: #fff; display: block; height: 30px; border: 0; outline: none;
          @include common-bdy-font; font-size: 14px;
        }
      }
      .mega-search-content{ padding: 0; margin:30px 0 0 0; width: 100%; position: relative;
        .head-sec{ padding: 0; margin:0 0 15px 0; width: 100%; display: flex; align-items: center; justify-content: space-between; @include common-bdy-font; font-size: 15px; color: #333333; font-weight:500;
          i{font-size: 13px;}
          span{ padding: 0; margin: 0;}
          a{padding: 0; margin: 0; color: #333333; text-decoration: none;}
        }
        .search-row{ padding: 0; margin:0 0 15px 0; width: 100%; display: flex; align-items: center; justify-content: flex-start;
          .image-box{ padding:0; margin: 0; width: 70px; height: 70px; display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
              img{
                box-sizing: border-box;
                height: 100%;
                object-fit: cover;
                }
            }
          .right-name-section{ padding: 0; margin: 0 0 0 20px; width:calc(100% - 90px); display: flex; flex-direction: column;
            p{ padding:0; margin:0 0 6px 0; @include common-bdy-font; font-weight: 500; font-size: 15px;  }
            span{ padding: 0; margin: 0; @include common-bdy-font; font-size:14px; text-transform: uppercase; color: #999999;}
          }
        }

      }
    }

  }
.search:hover + .mega-search{opacity: 1; pointer-events: all; z-index:2;}

/* /// HEADER END /// */

/* main header */
  .main-banner{ padding: 0; margin: 0; width: 100%;
    .banner-inner-sec{ padding: 0; margin: 0; width: 100%; height: 600px; position: relative; overflow: hidden; display: flex;
      justify-content: center;
      align-items: center;
      img{box-sizing: border-box;
        width: 100%;
        height: auto;
        object-fit: cover;}
        .banner-text-area{ padding: 0;
          margin: 0 auto;
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          top: 200px;
          display: flex;
         text-align: center;
         justify-content: center; flex-direction: column;
            h1{ padding: 0; margin: 0; @include heading-64; color: #fff; text-align: center; font-weight: 600; letter-spacing: 1px;
              text-shadow: 2px 4px 11px rgba(0,0,0,1);
            }
            p{ padding:15px 0 0 0; margin: 0; text-shadow: 2px 4px 11px rgba(0,0,0,1); color: #fff; text-align: center; font-weight:400; font-family: $projet-common-font;
             font-size: 20px;}
            .btn-row{ padding: 0; margin:30px 0 0; width: 100%; display: flex; align-items: center; justify-content: center;
              button{max-width: 170px; padding:0 25px}
            }

        }
    }
  }
/* main header */
/* home collection section */
.colection-sec{ padding: 0 0;
                margin: 0;
                width: 100%;
                display: flex;

    .collection-img-sec{padding: 0; margin:0 15px; width: calc(33.3% - 15px); height: 420px; display: flex;align-items: center;
      justify-content: center; position: relative; overflow: hidden;
      img{box-sizing: border-box;height: 100%;object-fit: cover;}
      h4{ font-size: $font-style-4; color: #fff; text-shadow: 2px 4px 11px rgb(0 0 0); position: absolute; bottom:10px; font-family: $project-heading-font; font-weight: 500;}

    }
}
/* home collection section */
.see-all{ padding: 40px 0 0; margin: 0; width: 100%; display: flex; align-items: center; justify-content: center;
  a{ display: inline-block; font-family: $projet-common-font; text-transform: uppercase; font-size: 15px; color: #333333; text-decoration: none; }
}

/* Arrival Section */
.arrival-sec{ padding:120px 0 0;margin:0; width: 100%; display: flex;
  .arrival-img-block{padding: 0; margin:0 15px; width: calc(50% - 15px); height:640px; display: flex;align-items: center;
    justify-content: center; position: relative; overflow: hidden;
    img{box-sizing: border-box;height: 100%;object-fit: cover;}

    .text-sec{
      padding: 0;
      margin: 0;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center; flex-direction: column;

        h3{font-size: $font-style-3; color: #fff; text-shadow: 2px 4px 11px rgb(0 0 0); bottom:10px; font-family: $project-heading-font; font-weight: 500;
          margin: 0; padding: 0;}
          .btn-row {
            padding: 0;
            margin: 30px 0 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;}
            button {
              max-width: 170px;
              padding: 0 25px;
          }
    }
  }
  .arrival-img-block:first-child{ margin-top:65px}
}
/* Arrival Section */

/* Categories Section */
.categories-sec{ padding:0;margin:0; width:100%; display: flex;
  .categories-sec-img-block{padding: 0; margin:0 15px; width: calc(25% - 15px); height:410px; display: flex;align-items: center;
    justify-content: center; position: relative; overflow: hidden;
    img{box-sizing: border-box;height: 100%;object-fit: cover;}
    .text-section{
      padding:6px 20px;
      margin: 0;
      width:auto;
      bottom:35px;
      height: 50px;
      position: absolute;
      background-color: #fff;
      font-family: $project-heading-font;
      z-index: 2;
      font-size:$font-style-5;
      color:#111111;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      font-weight:600;
      justify-content: center; flex-direction: column;}
  }

}
/* Categories Section */

/*  What our customers say */
.ustomers-say{ padding:20px 0 50px;margin: 0; width:100%; display: flex; align-items: flex-start;
  .ustomers-comment-block{padding: 0; margin:0 15px; width: calc(33.3% - 15px); display: flex;align-items: flex-start;
    justify-content:flex-start; position: relative; overflow: hidden; flex-wrap: wrap; }
    .rating-section{ padding:0; margin:0 0 25px 0; width:auto;
      ul{ padding: 0; margin: 0; list-style: none; display: flex; flex-direction: row;
          li{ padding:0 0 0 8px; margin:0 5px 0 0; list-style: none; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; background-color: #25AC6B;
            box-sizing: border-box;
            i{ padding: 0; margin: 0; font-size: 14px; color: #fff;}
          }
      }
    }
    p{ padding:0 0 25px 0; margin: 0; font-size: 16px; color: #333333; @include common-bdy-font; font-weight:500; text-align: left;}
    span{ padding: 0 0 0 25px;
      margin: 0;
      font-size: 15px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      letter-spacing: 0.6px;
      color: #666666;
      position: relative;}
    span:before{content: " ";
      padding: 0;
      margin: 0;
      width:14px;
      height: 2px;
      background-color: #999999;
      position: absolute;
      top: 11px;
      left: 0px;}
}

/*  What our customers say */

/* Your favorites */
.your-favorites{ padding:0;margin:0; width:100%; display: flex;}
/* Your favorites */

/* product block */
.product-block{padding: 0; margin:0 15px; width: calc(25% - 32px); border: #DDDDDD 1px solid; align-self: flex-start;  /*max-width: 306px;*/
  position: relative; /*overflow: hidden;*/ flex-wrap: wrap;
    .new-tag{padding:2px 8px; margin: 0; position:absolute; left:12px; top:12px; background-color: #fff; @include common-bdy-font; font-size: 12px;
      box-sizing: border-box; text-transform: uppercase; z-index: 2;}
    a.like-tag{padding:0; margin: 0; width: 26px; height: 26px; display: flex; align-items: center; justify-content: center; position:absolute; right:12px; top:12px; background-color: #fff;
        box-sizing: border-box; border-radius: 60%; outline: none;cursor: pointer; font-size: 14px; z-index: 2;
        i{font-size: 14px; color:#8f8c8c;}
      }
      a.like-tag.active{
        i{color:#f44336;}
      }

      .incart-tag{padding:2px 8px; margin: 0; position:absolute; left:12px; top:40px; background-color: #174E6D; @include common-bdy-font; font-size: 12px;
        box-sizing: border-box; text-transform: uppercase; z-index: 2;color: white;font-weight: 500}

    .product-img-sec{
      padding: 0; margin: 0; width: 100%; height: 350px; display: flex; align-items: center; justify-content: center; position: relative; overflow: hidden;
        a{height: 100%;}
        button.order-product{position: absolute;
          bottom: 16px;
          width: 88%;
          height: 40px;
          font-size: 14px;
          transition: opacity 1s;
          opacity: 0;
        }
      img{box-sizing: border-box;height: 100%; width: 100%;}
    }
    .product-img-sec:hover button.order-product{display: flex; opacity: 1;}
    .product-name{padding:2px 15px 10px 15px; margin: 0; width: 100%; box-sizing: border-box; font-size: 15px; font-weight: 600; color: #333333; text-align: left;
     border-bottom: #DDDDDD 1px solid; font-family: $projet-common-font;
      a{color:#333!important;}
    }
    .product-details-sec{padding:8px 0 18px 0; margin: 0; width: 100%; box-sizing: border-box;
      .set-of-sec{ padding:0 15px; margin:15px 0 0; display: flex; flex-direction: row; align-items: start;
        .set{ display:inline-block; padding-right: 10px; @include common-bdy-font; font-size: 14px; font-weight:300; }
        ul.quantity{ padding: 0; margin: 0; display: inline-flex; list-style: none; flex-direction: row; flex-wrap: wrap;
          li{ padding:0; margin:0 8px 0 0; min-width: 25px; max-width: 35px; height: 25px; display: flex; align-items: center; justify-content: center;
            span{ padding:0; margin: 0; font-size: 13px;}
          }
          li.primary{background-color:$primary-color; color: #fff; border:$primary-color 1px solid; margin-bottom: 8px; min-width: 25px;}
          li.gray{background-color:#fff; color: $primary-color; border: #DDDDDD 1px solid; margin-bottom: 8px; min-width: 25px;}
        }
      }
      .dia-sec{ padding:0 15px; margin:6px 0 0; display: flex; flex-direction: row; align-items: center; box-sizing: border-box;
        .dia{ display:inline-block; padding-right: 10px; @include common-bdy-font; font-size: 14px; font-weight:300; }
        ul.size{ padding:4px 0 0 0; margin: 0; list-style: none;  display: flex; flex-direction: row; flex-wrap: wrap;
          li{padding: 0; margin: 0; @include common-bdy-font; color: #333333; font-weight:500; font-size: 14px; letter-spacing: 0;}
          }
        }
    }

    .price-row{padding:0 15px; margin:6px 0 0; display: flex; flex-direction: row; align-items: center; box-sizing: border-box;
      .price-sec{padding: 0 15px 0 0; margin: 0; display: flex; padding-right: 10px; @include common-bdy-font; font-size: 14px; font-weight:300;
        span{color: #333333; font-weight:500; }
      }

    }
    .mob-button-row{padding:0 15px; margin:16px 0 0; display: none; flex-direction: row; align-items: center; box-sizing: border-box;
      button{width: 100%;box-shadow: none;font-weight: 400;height: 40px;}}

}

.sample-row{ padding:0; margin: 0; width: 100%; display: flex; padding: 13px; padding-bottom: 0; box-sizing: border-box;
  a.sample-box{ padding: 0; margin:0 2px; width:calc(100% / 6); height:40px; object-fit: fill; outline: none; cursor: pointer; position: relative;
    display: flex; align-items: center; justify-content: center; max-width: 44px;
    div.cus-tooltips{ padding: 5px 6px;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: #fff;
      margin: 0 auto;
      position: absolute;
      top: 45px;
      display: none;
      background-color:rgba(0,0,0,0.8);
      min-width: 120px;
      text-align: center;
      line-height: 11px;
     }

    img{ padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;}
  }
}

.sample-row a.sample-box:last-child{ background-color: #637c8a; display: flex; align-items: center; justify-content: center; font-size: 11px; color: #000;
  span{padding: 0; margin: 0; text-align: center; font-weight: 600;}
}
.sample-row a.sample-box:hover{ opacity: 0.8;}
.sample-row a.sample-box:hover .cus-tooltips{display: flex;}
/* product block */


/* section heading */
  .section-head{ padding: 84px 20px 55px; margin:0; width: 100%; box-sizing: border-box; text-align: center;
    h2{ @include heading-40; color:#111111; padding: 0; margin: 0; letter-spacing: 1px;}
    p{padding:10px 0 0; margin: 0; @include common-bdy-font; text-align: center; }
  }
/* section heading */


/* Project button style */
.primary-btn{ @include btn-style-blue;}
.yellow-btn{ @include btn-style-yellow}
.primary-base-btn{ @include btn-style-white; animation-duration: 4s;}
.primary-base-btn:hover{background-color: #edf0f1; }
.line-btn{ @include white-line-button}
.normal-sml-line-btn{ @include white-line-button-normal}
.primary-base-blue-btn{ @include btn-style-blue; animation-duration: 4s;}
// .primary-base-blue-btn:hover{background-color: #edf0f1; }
/* Project button style */

/* footer */
footer{ padding:80px 20px; margin:0 0 0; background-color:#BFCBD0; box-sizing: border-box;
  .footer-inner-section{padding: 0;margin: 0 auto;width: 1325px;
    .top-section{ padding: 0;
                  margin: 0;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  ul{ padding:0 25px 0 0; margin: 0; list-style: none; box-sizing: border-box; display: flex; flex-direction: column;
                    li{ padding: 4px 0; margin: 0; display: inline-flex; @include common-bdy-font; color:#333333; font-size: 15px; font-weight:400;
                      a{ color:#333333; text-decoration: none; cursor: pointer;}
                    }
                    li:first-child{ padding:0 0 20px 0; margin: 0; display: inline-flex; color:$primary-color; font-size: 16px; font-weight: 600; text-transform: uppercase;}
                  }
    }
    .footer-mobile{ padding: 0; margin: 0; width: 100%;display: none;
      .mat-accordion{ width: 100%;}
      .mat-expansion-panel-header-title{ @include common-bdy-font; font-size:16px; font-weight: 600; color: #174E6D;}
      .mat-expansion-indicator::after{padding: 5px!important; color: #174e6d; }
      .mat-expansion-panel-body {padding: 0 5px 6px!important;}
      .mat-expansion-panel-header{padding: 0 5px!important;}
      .mat-expansion-panel-spacing {margin: 0 0 !important;}
      .mat-expansion-panel{background-color: transparent !important;}
      .mat-expansion-panel:not([class*=mat-elevation-z]){ box-shadow: none!important;}
      ul{ padding: 0; margin: 0; list-style: none;
        li{ padding: 0; margin: 0;
          a{ padding:0 0 12px 0; margin: 0; width: 100%; font-size: 15px; @include common-bdy-font; color: #333333; display: block; }
        }
      }
    }
    .bottom-section{ padding: 0; margin:100px 0 0 0; width: 100%; display: flex; justify-content: space-between;
      .left{ padding:0 50px 0 0; margin: 0; display: inline-flex; width: 50%; box-sizing: border-box;
        ul{ padding: 0; margin: 0; width: 100%; list-style: none; display: flex;
          li{ padding:0 25px 0 0; margin: 0; color: #555555; @include common-bdy-font; font-size: 14px; font-weight:400;
            a{ padding: 0; margin: 0; list-style: none; color: #555555; cursor: pointer;}
          }
        }
      }
      .right{ padding: 0; margin: 0; display: inline-flex; width: 50%;
        ul{ padding: 0;
          margin: 0 80px 0 0;
          width: 100%;
          list-style: none;
          display: flex;
          justify-content: flex-end;
          li{ padding:0 0 0 40px; margin: 0; color: #555555; font-size: 14px; font-weight:400;
            a{ padding: 0; margin: 0; list-style: none; color:#333333;
              i{color:#333333; font-size: 25px;}
            }
          }
        }
      }
    }
  }
}
/* footer */

/* Login page */
.login-body{ padding: 0; margin: 0; width: 100%; min-height:800px; position: relative; overflow: hidden; display: flex; justify-content: center; align-items: center;
  background-image:url(https://d1if7r1bmiltgp.cloudfront.net/assets/images/login-bg.JPG); background-position: center center; background-size: cover;
  .login-box{ padding:40px 50px 40px; margin:70px 0 80px; width: 500px; box-sizing: border-box; background-color: #fff; position: relative;
    a.login-cross{ padding: 0; margin: 0; position: absolute; top:20px; right:20px; width: 20px; height: 20px; border: #ccc 1px solid; border: 0; outline:none; cursor: pointer;
      display: flex; align-items: center; justify-content: center; display: none;
      i{font-size: 18px;}
    }
    h2{ padding:0; margin: 0; width: 100%; font-family: $project-heading-font; font-size: 32px; font-weight: 600; color: #111111; text-align: center;}
    p.send{@include common-bdy-font; padding: 0; margin:15px 0 0; font-weight:400; width: 100%; text-align: center; font-size: 13px;}
    .login-box-inner{ padding: 0; margin:50px 0 0 0; width: 100%; height: auto;
        button{width: 100%; box-shadow: none; font-size: 15px;}
    }
  }

}

/* Login page */

/* Registration page */
.login-box.registration{ width: 650px;
  .info{ @include common-bdy-font; padding-bottom: 24px;}
}
.imnot-robot{padding:10px; margin:0 0 10px 0; box-sizing: border-box; -moz-box-sizing: border-box; height: 80px; width: 100%; border: #999999 1px solid;
  background-color: #F9F9F9;
}
/* Registration page */

/* --- listing page ---*/
.top-steper-section{ padding:8px 0; margin: 0; background-color: #EEECE9;
  .inner-section{
    margin: 0 auto;
    width: 1325px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    ul{ padding: 0; margin: 0; list-style: none; display: inline-flex;
      li{ padding: 0; margin: 0; display:inline-flex;
        a{ padding:0 15px; margin: 0; border: 0; outline: none; position: relative; text-decoration: none; @include common-bdy-font; color:#999999; font-size: 13px;}
        a.active{color: #666666; font-weight: 500;}
        a:after{content: "\e90e";
          padding: 0;
          margin: 0;
          position: absolute;
          font-family: "icomoon",sans-serif !important;
          font-size: 11px;
          right: -5px;
          top: 5px;
        }

      }
      li:first-child a{padding-left: 0;}
      li:last-child a:after{display: none;}
    }
  }
}



.inner-head{ padding:25px 0 40px; margin: 0; width: 100%; box-sizing: border-box;
  h2{ padding: 0; margin: 0; font-size:$font-style-2; font-family:$project-heading-font; color:#111111; font-weight: 600; line-height:40px; }
}

.shorter-section{ padding:0; margin: 0; width: 100%; display: flex;
  .left-section{ padding: 0; margin: 0; width:calc(100% - 300px);
    .category-top-sec{ padding: 0; margin: 0; width: 100%;
      ul{ padding: 0; margin: 0; list-style: none;
        li{ padding: 0; margin:0 8px 6px 0; display: inline-block;
          mat-select{min-width: 100px;border: #DDDDDD 1px solid;padding: 6px 8px; width: auto; color: #333333; font-size: 14px; font-weight: 500;
          font-family: $projet-common-font;
          .mat-mdc-option{min-height: 40px!important; min-height: 48px!important;font-size: 14px!important;}
          .mat-mdc-option .mat-pseudo-checkbox-full { margin-right: 10px!important;}
           }

         button{ border:#DDDDDD 1px solid; height: 40px;padding-left: 16px;padding-right: 16px; font-size: 14px; border-radius: 0px;
          i{font-size: 12px; margin-left: 16px;}
        }
        .mdc-list-item__primary-text{font-size: 14px!important;}
        }
      }
    }

  }
  .right-section{padding: 0 2px 0 0;
    margin: 0;
    width: 365px;
    height: 45px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    .items-results{ @include common-bdy-font; display: inline-block; padding-top: 8px; }
      ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        mat-form-field{ position: relative !important;
          .custom-dropdown-position{right: 0px!important;}
        }

        .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {height: 40px!important;}
        .mat-mdc-form-field-flex {
  height: 40px;
      }
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {padding-top: 7px;padding-bottom: 10px; width: 110px;}
      .mat-mdc-option{min-height: 38px!important;}

        button {
          border: #DDDDDD 1px solid;
          height: 40px;
          padding-left: 16px;
          padding-right: 16px;
          font-size: 14px;
          border-radius: 0px;
                i {
                  font-size: 12px;
                  margin-left: 16px;
                  }
            }
          }
      }

  }
}
.listing-chiper-section{ padding: 0; margin: 0; width: 100%;
  .chiper-section{ padding: 0; margin:18px 0 0 0; width: 100%;
    ul{ padding: 0; margin: 0; list-style: none;
      li{ padding: 0; margin:0 8px 8px 0; display: inline-block; font-size: 13px!important; color: #666666; @include common-bdy-font; text-transform: uppercase;
        button{ border:#DDDDDD 1px solid; height: 30px;padding-left:10px;padding-right:10px; font-size: 13px; border-radius: 0px;
           background-color: #EEECE9;
          i{font-size: 9px; margin-left: 8px;}
        }
        a{ color: #666666;}
      }
      li:last-child{margin-left:10px}
    }
  }
}
.listing-wrap{ padding:20px 0 30px; margin:0 -15px; box-sizing: border-box; -moz-box-sizing: border-box; display: flex; flex-wrap: wrap;
  .product-block{ margin-bottom: 30px;}
}
.page-stepper-section{ padding:0 0 60px 0; margin:0; width: 100%; display: flex; align-items: center; justify-content: space-between;
  .showing-section{ padding: 0; margin: 0; @include common-bdy-font;}
  .page-count-sec{ padding: 0; margin:0; display: inline-block;
    ul{ padding: 0; margin: 0; display: inline-flex; list-style: none;
      li{ padding: 0; margin:0 0 0 15px; display: inline-flex;
        a{ padding: 0; margin: 0; width: 40px; height: 40px; border: #DDDDDD 1px solid; display: flex; align-items: center; justify-content: center; font-size: 12px;
          @include common-bdy-font; text-decoration: none; outline: none; color: #333333;
        }
        a.active{ border: #174E6D 1px solid; color: #174E6D;}
      }
    }
  }
}
a.filter-block{ padding:0 10px 0 15px; margin: 0; border: #DDDDDD 1px solid; color: #333333; @include common-bdy-font; font-size:14px; position: relative;
  height: 38px; cursor: pointer; text-decoration: none; display: flex; align-items:center; justify-content: center;
  p{ padding: 0; margin: 0;
    span{color: #174e6d;}
  }
  i{color: #333333;font-size: 13px;padding-left:10px;}
  .filter-drop{ padding:15px;
    margin: 0;
    min-width: 300px;
    min-height: 400px;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 90;
    -webkit-box-shadow: 1px 2px 25px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 2px 25px -9px rgba(0,0,0,0.75);
    box-shadow: 1px 2px 25px -9px rgba(0,0,0,0.75); display: none;
    overflow-y: auto!important;
    .drop-search{ padding: 5px 10px;
      margin: 0 0 12px 0;
      width: 100%;
      border: #DDDDDD 1px solid;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      .search-icn{ padding: 0;
        margin: 0;
        width: 22px;
        height: 22px;
        color: #333333;
        position: absolute;
        left: 10px;
        i{padding-left: 0;}
      }
      input{ padding: 0 0 0 20px; margin: 0; width: calc(100% - 25px); border: 0; outline: none; @include common-bdy-font; font-size: 14px;}
    }
    ul.filter-list{ padding:0; margin: 0; width: 100%; list-style: none; max-height: 320px;
      li{ padding: 0; margin: 0 0 6px 0!important; display: flex; align-items: center; justify-content: space-between;}
    }
  }
  .filter-drop.show{display:block;}
}
/* --- listing page ---*/

/* forom section */
.row-cus{
  padding: 0 0 20px;
  margin:0 -15px;
  display: flex; flex-wrap: wrap;
}
.lablerow{
  padding: 0 0 10px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // font-weight: 500!important;
  @include common-bdy-font;
  a{color: #333333;}
  span{padding: 0;margin: 0;margin-right: auto;display: inline-block;}
}
 mat-form-field{ font-family:$projet-common-font;
  width: 100%; font-size: 14px;
}
.mat-mdc-form-field-subscript-wrapper {height: 10px;}
.mat-mdc-text-field-wrapper {height: 50px!important;}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {padding-top: 12px;padding-bottom: 12px;}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading{border-radius: 0px!important;}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing{border-radius: 0px!important;}
.mat-accent {
  --mat-option-selected-state-label-text-color:#76949F!important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color:#76949F!important;
  background-color: #76949F!important;
}
.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  opacity:#76949F!important;
  background-color:#76949F!important;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: ##174E6D;
  --mdc-radio-selected-hover-icon-color: ##174E6D;
  --mdc-radio-selected-icon-color: ##174E6D;
  --mdc-radio-selected-pressed-icon-color: ##174E6D;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: ##174E6D;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color:#76949F;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color:#76949F;
  --mat-tab-header-active-ripple-color:#fff;
  --mat-tab-header-inactive-ripple-color:#fff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color:#76949F;
  --mat-tab-header-active-hover-label-text-color:#76949F;
  --mat-tab-header-active-focus-indicator-color:#76949F;
  --mat-tab-header-active-hover-indicator-color:#76949F;
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color:$primary-color!important; font-size: 15px; font-weight: 600;
}
.mat-mdc-tab .mdc-tab__text-label {
  color:#999999!important; font-size: 15px; font-weight: 600;
}
/* forom section */

/* order details */
.order-details-top{
  padding:25px 0 50px; margin: 0; width: 100%; display: flex;
  .left-section{ padding: 0; margin: 0; width: 50%;
    .owl-carousel{ position: relative;
      .owl-dots{margin-top: 12px;}
      .owl-nav {
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: -35px;
        display: flex;
        justify-content: space-between;
        .owl-prev{ margin-left:20px;
          .material-icons{padding-left: 6px;}
        }
        .owl-next{ margin-right: 20px;}
        .owl-prev, .owl-next{
          background-color: #fff;
          color: #000;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center; height: 30px; width: 26px;
          .material-icons{font-size: 18px!important;}
        }
        }
    }
    .details-product-block{ padding: 0; margin:0 -5px; width: 100%; height: 650px; border: #000 1px solid;
      display: flex;align-items: center;justify-content: center; overflow: hidden; position: relative;
        img{box-sizing: border-box; height: 100%;}

        a.like-tag {
          padding: 0;
          margin: 0;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 16px;
          top: 16px;
          background-color: #fff;
          box-sizing: border-box;
          border-radius: 60%;
          outline: none;
          cursor: pointer;
          font-size: 14px;
          z-index: 2;
            i{font-size: 20px;
              color: #a5a4a4;}
      }
      .like-tag.active{
        i{color:#f44336 ;}
      }
    }
    .showing-at{ padding: 0; margin: 0; width: 100%; display: flex; align-items: center; justify-content: center; @include common-bdy-font; justify-content: center;}
  }
  .right-section{ padding:0 0 0 30px; margin: 0; width: 50%; box-sizing: border-box;
    .color-palate-tab-sec{ padding: 0; margin:10px 0; width: 100%; display: flex;
        a.color-tab{ padding:10px; margin: 0; box-sizing: border-box; -moz-box-sizing: border-box; width: calc(50% - 0px); font-size: 14px; @include common-bdy-font; justify-content: center;
          display: flex; align-items: center; cursor: pointer; color: #333; border:#DDDDDD 1px solid;
        }
     }
     .color-palate-tab-sec a.color-tab:first-child{border-right:0px ;}
     .color-palate-tab-sec a.color-tab:last-child{border-left:0px ;}
     .color-palate-tab-sec a.color-tab.active:first-child{border-right:1px #174e6d solid; color:#174e6d; border-color:#174e6d; font-weight: 600;}
     .color-palate-tab-sec a.color-tab.active:last-child{border-left:1px #174e6d solid; color:#174e6d; border-color:#174e6d; font-weight: 600;}

    .right-head{ padding: 0; margin: 0; width: 100%; display: flex; align-items: center; justify-content: center;  flex-direction: column;
      h4{ padding: 0; margin: 0; width: 100%; text-align: center; @include heading-bdy-font; font-size: 32px; color: #111111; font-weight: 600;}
      span{padding:15px 0 0 0; margin: 0; color:#999999!important; @include common-bdy-font; font-size: 13px!important;}
          }
    .table-tab-section{ padding: 0; margin:20px 0 0 0; width: 100%;
          .tab-content-body{ padding:20px 10px; margin: 0; width: 100%; box-sizing: border-box;}
          .mdc-tab-indicator{top:5px!important;}
          .mdc-tab-indicator__content--underline{border-bottom:#174D6D 3px solid!important;}
          .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
            border-bottom:#DDDDDD 3px solid!important;
            margin: 0 8px !important;
            }
            .no-of-set-sec{ padding: 0; margin: 0; display: flex; width: 100%; align-items: center;
              button{ margin-right: 10px; color: #333333;}
              button.active{border-color: #174D6D; color:#174D6D; font-weight: 600;}
              span{ padding: 0; margin: 0; @include common-bdy-font; font-size: 14px; color: #333333; margin-left: auto;}
            }
            .chart-table{ padding: 0; margin:18px 0 0 0; width: 100%;
              table{padding: 0; margin: 0; width: 100%; @include common-bdy-font; font-size: 13px;
                thead{
                  tr{ background-color: #BFCBD0;}
                }
                th{  color: #111111; font-weight: 400; text-align: center;}
                td{color: #333333; font-weight: 400; text-align: center; border-bottom: #DDDDDD 1px solid;}
              }
              table tr:last-child td{font-weight: 600; text-align: left; }
              table tr:last-child td:last-child{font-weight: 600; text-align: center; }
            }
            .set-count-section{ padding: 0; margin:22px 0 0 0; width: 100%; display: flex; align-items: center; justify-content: space-between;
              @include common-bdy-font; font-size: 14px; color: #333333;
              .left, .right{padding: 0; margin: 0; display: inline-flex; align-items: center; font-weight: 500; }
              .center{padding: 0; margin: 0; display: inline-block; font-weight: 600;}

              .left p, .right p{padding:0 12px 0 0; margin: 0; display: inline-block;}
            }
            .cunt-btn-row{ padding: 0; margin:20px 0 0; width: 100%; display:flex; justify-content: center;
              button{height: 35px; box-shadow: none; letter-spacing: 1px; font-weight: 500;}
            }
        }
        .value-row{ padding:  0 45px; margin: 0; box-sizing: border-box; width: 100%; @include common-bdy-font; font-size: 14px; display: flex; align-items: center; justify-content: space-between;
          p{display: inline-flex;
            span{font-weight: 600; color: #333333;}
          }
        }
        .chose-quantity{padding:0 0 20px 0; margin:28px 0 0; width: 100%; @include common-bdy-font; font-size: 14px; color: #999999; display: flex; align-items: center; justify-content: flex-start;

           h5{ padding: 0; margin: 0; font-size:20px!important; font-weight: 600!important; @include heading-bdy-font;letter-spacing: 0!important; color: #111111;
            span{color: #9D876D; }
          }
           .count-wrap{margin-left: auto;}
          }

          .chose-color{ padding: 0; margin: 0;
            .chose-quantity{padding: 0; margin:25px 0 10px; width: 100%;
              .filter-by-color{@include common-bdy-font; padding: 0; margin: 0; display: inline-block; font-size: 14px; margin-left: auto; color: #333333; font-weight: 500;}
            }

              .choose-poduct-row{ padding:20px 0 0; margin:0 0 0; width: 100%; overflow-x: auto; display: flex;
                .choose-block-wrap{ padding:0 0 0; margin:0 3px 8px 3px; width: 100px; height: 100px; box-sizing: border-box; position: relative;
                  .incart{ padding:1px 2px; margin: 0; box-sizing: border-box; background-color: #174e6d; font-size: 12px; font-weight: 600; color: #fff; display: inline-block;
                    position: absolute; top:-18px; margin: 0 auto; left: 0; right: 0; width: 50px; text-align: center; z-index: 2; line-height:16px;
                  }
                  .choose-img-block{padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    position: relative;
                    width: 100%;
                    height: 100px;
                    cursor: pointer;
                    .selected{ padding:0; margin: 0; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.38);
                      display: none; align-items: center; justify-content: center; font-size:26px; color: #fff;
                    }
                    img {
                      box-sizing: border-box;
                      height: 100%;
                      margin: 12px;
                      object-fit: cover;
                  }
                }
                .choose-img-block.active{
                  .selected{display: flex;}
                }
                }

              }

          }
          .button-row{ padding:12px 0;
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            button{width:calc(50% - 15px);}
            .primary-base-btn.total-set{ font-size: 14px;
              text-transform: capitalize;
              padding:6px 14px;
              box-shadow: none;
              background-color: #EEECE9;
              border-bottom: #25AC6B 6px solid;}
          }
          .primary-base-btn.add-container{background-color: #9D876D; color: #fff; box-shadow: none;}
  }
}

.features-section{ padding:90px 0 0 0; margin: 0; box-sizing: border-box;
    p{ padding: 0 0 15px 0; margin: 0; @include heading-bdy-font; font-size: 20px; font-weight: 600; text-align: center;}
    .features-block{ padding: 0; margin:0 -5px; display: flex; flex-direction: row; flex-wrap: wrap;
      .f-block{padding:15px; margin:5px; background-color: #F6F6F6; width: calc(50% - 10px); height: 80px; display: flex; align-items: center;
        justify-content:flex-start; box-sizing: border-box; -webkit-box-sizing: border-box; @include common-bdy-font; font-size: 15px; color: #333333; font-weight: 500;
        i{padding-right: 25px; margin: 0; font-size: 38px;}
      }
    }
}

.container-details{ padding:12px 15px 8px; margin:32px 0 0; border: #DDDDDD 1px solid; box-sizing: border-box; -webkit-box-sizing: border-box; display: flex; align-items: center;
  flex-direction: row; flex-wrap: wrap;
  .left-bar{ padding: 0; margin: 0; width: 55%;
    p{padding:0 0 12px 0; margin: 0; @include common-bdy-font; color: #333333; text-align: left; font-weight: 500;}
    .content-size-row{padding: 0; margin: 0 0 8px 0; width: 100%; display: flex; flex-direction: row; align-items: center;
      .left{padding:0; margin: 0; @include common-bdy-font; font-size: 13px; width: 70px; font-weight: 500;}
      .graph-bar{padding: 0; width: 210px; height: 15px; border: #DDDDDD 1px solid; width: calc(100% - 140px);
        .graph-persentige{ padding:0; margin: 0; background-color:#25AC6B; display: block; height: 100%;}
      }
      .right-persentage{padding-left:12px; @include common-bdy-font; font-size: 13px; font-weight: 500;}
    }
  }

  .left-bar-collection{ padding: 0; margin: 0px; width: 100%; margin-top:12px ;
    p{padding:0 0 5px 0; margin: 0; @include common-bdy-font; color: #333333; text-align: left; font-weight: 500; font-size: 14px;}
    .content-size-row{padding: 0; margin: 0 0 8px 0; width: 100%; display: flex; flex-direction: row; align-items: center;
      .left{padding:0; margin: 0; @include common-bdy-font; font-size: 12px; width: 60px; font-weight: 500;}
      .graph-bar{padding: 0; width: 210px; height: 15px; border: #DDDDDD 1px solid; width: calc(100% - 140px);
        .graph-persentige{ padding:0; margin: 0; background-color:#25AC6B; display: block; height: 100%;}
      }
      .right-persentage{padding-left:0px; @include common-bdy-font; font-size: 13px; font-weight: 500;}
    }
  }

  .truck-section{padding: 0; margin: 0; width: 45%; display: flex; justify-content: flex-end;
    img{ padding: 0; margin: 0; width:auto; height: 100%; max-width: 230px;}
    }
    .palate-count-sec{ padding: 0; margin: 0; display: flex; width: 100%; flex-direction:row; align-items: center;
      span{ padding: 0; margin: 0; display: inline-block; @include common-bdy-font; font-size: 13px; font-weight: 500; position: relative;}
      .tool-tip-custom{ padding:2px 5px; margin: 0; width: auto; box-sizing: border-box; background-color:#174e6d; color:#fff; font-size: 12px; text-align: center; position: absolute;
        display: none;
        top: -25px;
        z-index: 99;
        border-radius: 2px;
      }
      span:hover + .tool-tip-custom{ display: block;}
      ul{ padding: 0; margin: 0; display: inline-flex; list-style: none;
        li{ padding: 0; margin:0 0 0 15px; display: inline-flex; align-items: center; @include common-bdy-font; font-size: 13px;}
      }
    }
    .palate-count-sec.color-chat{display: flex; align-items: center; justify-content: flex-end; color: #174e6d!important; font-weight: bold; position: relative;
      button{ outline: none; box-shadow: none; letter-spacing: 1px; height:36px; font-size: 13px;}
      span{color:#174e6d!important ;}
    }


 }
 .quantity-plate-outer{ padding:0; margin: 0; width: 100%; max-height: 650px; overflow-y: auto;}
 .quantity-per-plate{ padding: 0; margin:0 0 0; width: 100%; ;
  .chose-quantity{ margin-top: 30px;}
  .qunt-block{ padding: 0; margin:0 0 10px 0; width:calc(100% - 2px); border: white 1px solid;

    .head{padding:8px 12px; margin: 0; @include common-bdy-font; color: #111111; font-weight: 600; font-size: 14px; background-color: #BFCBD0;
      box-sizing: border-box; -webkit-box-sizing: border-box;
      }
      .qunt-sec-body{ padding:8px 6px; margin: 0; box-sizing: border-box; -webkit-box-sizing: border-box; border: #DDDDDD 1px solid; display: flex; flex-wrap: wrap; justify-content: flex-start!important;
        position: relative;
        .qunt-sec{padding: 6px 6px 6px 6px;
          margin: 0;
          width: 50%;
          box-sizing: border-box;
          display: flex;
          border-bottom: #f3eded 1px solid;
          .img-block{ padding: 0; margin: 0; width: 70px; height: 70px; display: flex;align-items: center;justify-content: center;overflow: hidden;position: relative;
            img {
              box-sizing: border-box;
              height: 100%;
              object-fit: cover;
              }
            }
          .left-sec{ padding:0 0 0 12px; margin: 0; box-sizing: border-box; flex: 1; position: relative;
               a.cross-icn{ padding: 0; margin: 0; position: absolute; right: 0; top:5px; font-size: 16px; color: #000000; cursor: pointer;}
              .product-name{ padding:0 20px 8px 0; margin: 0; @include common-bdy-font; font-size: 14px; width: 100%; box-sizing: border-box; -webkit-box-sizing: border-box; }
              .bottom-row{ padding: 0; margin: 0; width: 100%; display: flex; align-items: center;
                  .price{ padding: 0; margin: 0; @include common-bdy-font; font-weight: 600; margin-left:auto;}
              }
          }
        }
      }

  }
  .qunt-block.color-error{ border: red 1px solid; width: calc(100% - 2px);}
}
.quantity-per-plate .qunt-block .qunt-sec-body:last-child .qunt-sec{border-bottom: 0px;}
.quantity-per-plate .qunt-block .qunt-sec-body:nth-last-child(2) .qunt-sec{border-bottom: 0px;}
.quantity-per-plate.add-color-quantity{
  .qunt-sec-body{min-height: 120px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
    button {
          height: 40px;
          box-shadow: none;
          letter-spacing: 1px;
          font-weight: 500;
          box-shadow: none;
          letter-spacing: 1px;
          position: absolute;
          left: 0;
          right: 0;
          width: 250px;
          margin: 0 auto;
      }
  }
}
.details-product-block{ padding: 0; margin:70px 0 0; width: 100%;
  h4{ padding:0 0 18px 0; margin:0; @include heading-bdy-font; color: #111111; font-weight: 600; font-size: 40px; line-height: 45px;}
  .listing-wrap{padding-bottom: 0;}
}
.details-product-block.customer-like{margin: 20px 0 40px 0;}
/* order details */

/* count box */
.count-wrap{ padding: 0; margin: 0; min-width: 110px; border:#999999 1px solid; font-size: 14px; color: #393E46; display: flex;
  a.btn{ padding: 0; margin: 0; width: 35px; height: 35px; color: #393E46; display: flex; align-items: center; justify-content: center; border: 0; outline: none;}
  .count-box{padding: 0; margin: 0; width: calc(100% - 70px); border-left:#999999 1px solid; border-right:#999999 1px solid; display: flex; align-items: center; justify-content: center;}
}
/* count box */

/* Container */
.new-container-add-sec{ padding: 0; margin: 0;
  ul{ padding: 0; margin: 0; display: inline-flex; list-style: none;
    li{ padding: 0; margin:0 10px 0 0; display: inline-flex;
      .primary-base-blue-btn{box-shadow: none; height: 41px; font-weight: 400; font-size: 14px; width: 210px;}
      .mat-mdc-text-field-wrapper{height: 40px!important;padding-top: 8px; padding-bottom: 6px; padding-right: 30px; position: relative;
        .mat-mdc-form-field-infix{min-height: auto!important; padding-top:0; padding-bottom: 0;}
        .mat-mdc-select-arrow-wrapper{display: none!important;}
      }
      .mat-mdc-text-field-wrapper:before{ content:"\e90a"; padding: 0; margin: 0; position:absolute; width: 20px; height: 20px; right:8px; font-size: 14px; font-family: 'icomoon',sans-serif !important;}
    }
  }
}

.cart-wrap{ padding:0 0 60px; margin:35px 0 0 0; width: 100%; display: flex;
  .cart-left{padding: 0; margin: 0 35px 0 0; width: calc(100% - 455px);
    h5{ padding: 0; margin: 0; @include heading-bdy-font; font-size: 24px; color: #111111; font-weight: 600; }
  }
  .cart-right{padding: 0; margin: 0; width: 420px;
    .product-top{ padding: 0; margin: 0;
      .values-and-price{ display: flex; justify-content: space-between;
        .price{font-weight: 600;}
      }
    }

    h5{ padding: 0; margin: 0; @include heading-bdy-font; font-size: 24px; color: #111111; font-weight: 600; }
    button.yellow-btn{width: 100%;box-shadow: none; font-size: 15px; margin-top: 15px; }
    button.line-btn{width: 100%;box-shadow: none; margin-top: 15px; box-shadow: none; }
    .container-details{margin: 20px 0 0;
      .left-bar {width: 100%;}
      .truck-section{ width: 100%;}
      .content-size-row .graph-bar {width:calc(100% - 150px);}
      .truck-section{ margin: 20px 0 0; justify-content: center;}
    }
  }
   /* checkout-page */
   .checkout-left{
    padding: 0;
    margin:-12px 35px 0 0;
    width: calc(100% - 455px);
    .mat-expansion-panel:not([class*=mat-elevation-z]){box-shadow: none;}
    .mat-expansion-panel-header{border-bottom: #DDDDDD 1px solid; padding: 0; }
    .mat-expansion-panel-header-title{ @include heading-bdy-font; padding: 0; margin: 0; font-size:24px; color: #111111; height: 30px; font-weight: 600;}
    .mat-expansion-panel-spacing {margin:8px 0 16px;}


  }
  .checkout-left .mat-accordion .mat-expansion-panel:last-child .mat-expansion-panel-header{border-bottom: #DDDDDD 0px solid; }


  /* checkout-page */
}
.address-wrap{ padding: 0; margin: 0; width: 100%;
  h5{ padding: 20px 0 15px; margin: 0; @include heading-bdy-font; font-size: 16px; color: #111111; font-weight: 600;}
  .address-box{ padding:20px 20px 20px 10px; margin:0 0 12px 0; box-sizing: border-box; border: #DDDDDD 1px solid; display: flex;
    .radio-sec{ padding: 0; margin:0 12px 0 0;}
    .address-sec{ padding: 0; margin: 0; width:auto; margin-right: auto;
      .name{ padding: 0; margin:0 0 15px 0; @include common-bdy-font; color: #333333; font-size: 14px;
          p{ padding: 0; margin: 0; font-weight: bold;}
      }
      .address{ padding: 0; margin: 0; @include common-bdy-font; color: #333333; font-size: 14px;
        p{ padding: 0; margin: 0;}
      }
    }
    .edit-sec{padding: 0;
      margin: 0;
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: self-end;
      justify-content: space-between;
      span.defult-address{ padding:5px 10px; margin: 0; background-color: #EEECE9; box-sizing: border-box; font-size: 11px;}
      a.edit{ padding: 0; margin: 0; font-size: 14px; @include common-bdy-font; text-transform: uppercase; color: #333333; cursor: pointer; text-decoration: none; }
    }
  }
  a.add-new-address{ padding:16px 20px; margin: 0; border: #DDDDDD 1px solid; box-sizing: border-box; @include common-bdy-font; font-size: 14px; text-transform: uppercase;
    color: #174D6D; display: flex; align-items: center;
    i{ font-size: 16px; padding-right: 12px;}
  }
  a.add-new-address.same-biling-address{ padding: 6px 20px 6px 10px; margin: 0; border: #DDDDDD 1px solid; box-sizing: border-box; @include common-bdy-font; font-size: 14px; text-transform: uppercase;
    color: #174D6D; display: flex; align-items: center;
  }
  .btn-row{ padding:0; margin:25px 0 15px 0; width: 100%; display: flex; justify-content: flex-start;
    button{padding-left: 60px; padding-right: 60px; box-shadow: none;}
  }
}
.shipping-method{padding:20px 0; margin: 0; width: 100%;
    .shipping-method-top{ padding: 0; margin: 0; border: #DDDDDD 1px solid;
        .method-row{ padding:6px 20px 6px 10px; margin: 0; box-sizing: border-box; border-bottom: #DDDDDD 1px solid; display: flex; align-items: center;
          .radio-sec {padding: 0;margin: 0 12px 0 0;}
          .text-sec {padding: 0;margin: 0;width: auto; @include common-bdy-font; color: #333333; font-size: 14px;}
        }
    }

    .shipping-form-section{ padding: 0; margin: 25px 0; box-sizing: border-box;
      .btn-row{ padding:0; margin:5px 0 15px 0; width: 100%; display: flex; justify-content: flex-start;
        button{padding-left: 60px; padding-right: 60px; box-shadow: none;}
      }
    }
}
.payment-method{padding:20px 0; margin: 0; width: 100%;
  .notice{@include common-bdy-font; padding:0 0 35px 0; margin: 0; color: #333333; font-size: 15px; font-weight:600;}
  p{@include common-bdy-font; padding:0 0 15px 0; margin: 0; color: #333333; font-size: 15px; font-weight:400;}
  .checksec{@include common-bdy-font; color: #333333; font-size: 15px; font-weight:400;}
  .btn-row{ padding:0; margin:15px 0 15px 0; width: 100%; display: flex; justify-content: flex-start;
    button{padding-left: 60px; padding-right: 60px; box-shadow: none; min-width: 350px;}
  }
}

.product-cart-wrap{ padding: 0; margin:25px 0 0; width:100%;
  .cart-total-btn-row{ padding: 0;
    margin: 0;
    width: calc(100% - 2px);
    height: 50px;
    display: flex;
    border: #DDDDDD 1px solid;
    border-top: 0px;
    .left-sec{ padding: 0; margin: 0; width:50%; background-color: #EEECE9; display: flex; align-items: center; @include common-bdy-font; font-size: 14px;
      font-size: 14px; position: relative; display: flex; align-items: center; justify-content: center; font-weight: 500;
      b{font-weight: 600;}
    }
    .left-sec:after{ content: ""; padding: 0; margin: 0; position: absolute; bottom: 0; height: 5px; width: 100%; }
    .right-sec{padding:15px; margin: 0; width:50%; display: flex; align-items: center; justify-content: flex-end; box-sizing: border-box;
        a{padding: 0; margin: 0; height: 100%; height: 100%; display: flex; align-items: center; justify-content: center; @include common-bdy-font;
          font-size: 13px; text-transform: uppercase; color: #333333; cursor: pointer; box-sizing: border-box; font-weight: 500;
        }
      }
      .right-sec a:first-child{padding-right: 50px;}
  }
  .block-head{ padding:10px 15px; margin: 0; background-color: #BFCBD0; display: flex;  @include common-bdy-font; color: #111111;
  justify-content: space-between; font-weight: 600; box-sizing: border-box;
    span{ padding: 0; margin: 0;}
   }
   .product-cart-row{ padding:20px 15px 10px 15px; margin: 0; width: 100%; box-sizing: border-box; display: flex; border:#DDDDDD 1px solid; border-top: 0px;
      .left-img-block{ padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100px;
        height: 100px;
        img {
          box-sizing: border-box;
          height: 100%;
          object-fit: cover;
        }
      }
      .right-text-section{ padding: 0; margin:0 0 0 20px; width: calc(100% - 120px); @include common-bdy-font;
        .top-name-sec{ padding: 0; margin:0 0 15px 0; width: 100%; display: flex;
          .name-sec{ padding:0 30px 0 0; margin: 0; width:45%; box-sizing: border-box;
            .sml-img-block{ padding: 0; margin:8px 0 0; width: 60px; height: 60px;
                              img {
                                box-sizing: border-box;
                                height: 100%;
                                object-fit: cover;
                              }
                      }
            h5{ padding: 0; margin: 0;  @include common-bdy-font; font-size: 14px; font-weight: 600; color: #333333; }
            p{  padding: 0; margin: 0; font-size: 13px; font-weight: normal; color: #999999;
              span{ color: #333333; font-weight: 500;}
            }

          }
          .size-sec{ padding:0; margin: 0; width:55%; box-sizing: border-box;
            p{ padding: 0; margin: 0; font-size: 13px; color: #333333; font-weight: 500; display: flex;
              span{ width:116px; color: #999999; display: inline-block; font-weight: 400;}
            }
          }
        }
        .bottom-qnt-sec{ padding: 0; margin:15px 0 0 0; width: 100%; display: flex; align-items: center; justify-content: space-between;
            .quantity-sec{ padding: 0; margin: 0; display: inline-flex; align-items: center;
              .count-wrap{
                min-width: 100px;
                a.btn{height: 30px; width: 30px;}
                .count-box{width: calc(100% - 60px);}
              }
              .amount{ padding:0 0 0 15px; margin: 0;  color:#333333; @include common-bdy-font; font-size: 14px; font-weight: 600;}
            }
            .edit-del-btn-sec{ padding: 0; margin: 0; display: inline-flex;
              a{ padding: 0 0 0 20px; margin: 0; @include common-bdy-font; text-transform: uppercase; font-size: 13px; color: #333333; border: 0px;
                outline: none; font-weight: 600;}
            }
        }
      }
  }

}

.summary-top{ padding: 0; margin: 0; width:100%;
  .subtotal-block{ padding: 0; margin:25px 0 0; border:#DDDDDD 1px solid;
    .row{padding:12px; margin: 0; box-sizing: border-box; @include common-bdy-font; color: #333333; display: flex; align-items: center; justify-content: space-between;
      border-bottom:#DDDDDD 1px solid;
      span{ padding: 0; margin: 0;}
    }
    .row:last-child{font-weight: 600;  border-bottom:#DDDDDD 0 solid;}
  }
}

.checkout-summary-top{ padding: 0; margin: 0; width:100%;
  .checkout-subtotal-block{ padding: 0; margin:25px 0 0; border:#DDDDDD 1px solid;
    .row{padding:12px; margin: 0; box-sizing: border-box; @include common-bdy-font; color: #333333; display: flex; align-items: center; justify-content: space-between;
      border-bottom:#DDDDDD 1px solid;
      span{ padding: 0; margin: 0;}
    }
    .row:last-child{font-weight: 600;  border-bottom:#DDDDDD 0 solid;}
  }
}
.note-sec{ padding: 0; margin:20px 0 0; @include common-bdy-font; color: #333333;
  .label{padding:0 0 10px 0; margin: 0; width:100%;}
  textarea{ padding: 12px; margin: 0; width: 100%; height: 100px;  @include common-bdy-font; box-sizing: border-box;}

}
/* Container */


/* pop-up */
.pop-up-body{ top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color:rgba(0, 0, 0, 0.4);
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;

}
.pop-box{ padding: 0; margin: 0; min-width:600px; background-color: #fff; position: absolute; z-index: 99;
  min-height: 200px;
  .pop-head{ padding: 20px; margin: 0; box-sizing: border-box;  @include heading-bdy-font; color: #111111; font-size: 24px; font-weight: 600;
    border-bottom: #DDDDDD 1px solid; display: flex; align-items: center; justify-content: space-between;
    a{ padding: 0;font-size: 16px; color:#333333; border: 0; outline: none; margin-left: auto; text-decoration: none; cursor: pointer;}
  }
  .add-order-quentity-box{padding: 20px; margin: 0; box-sizing: border-box; -webkit-box-sizing: border-box; display: flex; flex-direction: column;
      .addcolor-row{ padding:0; margin:8px 0; width: 100%; display: flex; justify-content: space-between; align-items: center;
        .left-imgblock{ padding: 0; margin: 0; display: inline-flex; align-items: center;
          .img-block{ padding: 0; margin: 0; width: 70px; height: 70px; display: flex;align-items: center;justify-content: center;overflow: hidden;
              img{box-sizing: border-box;height: 100%;object-fit: cover;}
                    }
                    p{display: inline-flex; @include common-bdy-font; font-size: 14px; padding:0 0 0 15px; margin: 0; flex-direction: column;
                      span{ padding: 0; margin: 0;}
                    }

        }
      }
      :first-child{margin-top: 0px;}
      :last-child{margin-bottom: 0px;}
      .pop-box .add-order-quentity-box{ margin-bottom: 10px;}
  }
  .add-order-quentity-box.color-view{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: auto!important;

    .row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      height: 367px;
      overflow-y: auto;
      .choose-img-block{
        margin: 10px !important;
        text-align: center;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        width: calc(33.3% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    }
  }

  .add-new-address{padding: 20px; margin: 0; box-sizing: border-box; -webkit-box-sizing: border-box; display: flex; flex-direction: column;

  }

  .bottom{ padding:10px 20px; margin: 0; box-sizing: border-box; border-top:#DDDDDD 1px solid; display: flex; align-items: center; justify-content: space-between;
    button{width:48%; box-shadow:none;}
    .primary-base-btn:hover {background-color: #ffffff;}

  }
}
.pop-box.plate-count{
  .top{ padding: 20px; margin: 0; box-sizing: border-box; -webkit-box-sizing: border-box; display: flex;
    .left, .right{ padding: 0; margin: 0; width: 50%;}
    .left{display: flex; align-items: center; justify-content: center; flex-wrap: wrap;
      .img-sec{ padding: 0; margin:0 0 12px 0; width: 150px; height: 150px;display: flex;align-items: center;justify-content: center;position: relative; overflow: hidden;
          img{box-sizing: border-box;height: 100%; object-fit: cover;}
      }
      .name{ padding: 0; margin: 0; @include common-bdy-font; font-size: 14px; font-weight: 500; color: #333333; display:block; width: 100%; text-align: center; }
      .price{ padding: 0; margin: 0; @include common-bdy-font; font-size: 14px; font-weight: 500; color: #333333; display:block; width: 100%; text-align: center; }
    }
    .right{
      .plate-row{ padding: 0; margin:0 0 15px 0; width: 100%; display: flex; justify-content: space-between; align-items: center;
          .check-section{ padding: 0; margin: 0; display: inline-block;  @include common-bdy-font; font-weight: 500;
            label{ @include common-bdy-font; font-weight: 500 !important;}
          }
      }
    }

  }
  .bottom{ padding:10px 20px; margin: 0; box-sizing: border-box; border-top:#DDDDDD 1px solid; display: flex; align-items: center; justify-content: space-between;
    button{width:48%; box-shadow:none;}
    .primary-base-btn:hover {background-color: #ffffff;}

  }
  .bottom.blk{
    .primary-base-btn{color: #000;}
  }
}
.pop-up-body.show{
  visibility: visible;
  opacity: 1;
}

.address-new-pop-inner{padding: 20px;
  margin: 0;
  box-sizing: border-box;
  overflow: auto;
  max-height: 450px;
  .row-cus{padding: 0 0 10px;}
  .check-row{ padding: 0; margin: 0;}
  .check-row{
    .row-cus{padding: 0 0 0!important;}
  }
}
/* pop-up */

/* my account */
.my-account-wrap{ padding:0; margin:0 0 40px 0; width: 100%; display: flex;
  .left-menu-list{ padding:15px 0; margin: 0; width: 310px; background-color: #EEECE9; box-sizing: border-box; -moz-box-sizing: border-box; max-height: 350px;
    ul{padding: 0; margin: 0; width: 100%; list-style: none;
        li{ padding:0 20px; margin: 0; width: 100%; box-sizing: border-box; border-left:4px #EEECE9 solid;
          a{ padding:0; margin: 0; @include common-bdy-font; font-size: 14px; color: #333333; font-weight: 500; box-sizing: border-box;
            height: 40px; display: flex; align-items: center;

          }


        }
        li:nth-child(3) a{ position: relative;}
        li:nth-child(3) a:after{content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          bottom: -6px;
          left: 0;
          background-color: #d5d5d5;}
        li:hover{ font-weight:600; color:#174D6D; border-left:4px #174D6D solid;}
        li.active{ font-weight:600; color:#174D6D; border-left:4px #174D6D solid;}
        li:hover a{ font-weight:600; color:#174D6D;}
        li.active a{ font-weight:600; color:#174D6D;}
      }
      ul.secound-list{ margin-top:16px}
      ul.third-list{ margin-top:16px}
  }
  .account-right-section{padding:0 0 0 30px; margin: 0; width:calc(100% - 310px); box-sizing: border-box; -moz-box-sizing: border-box;
      h4{ padding: 0 0 25px 0; margin: 0; @include heading-bdy-font; font-size: 24px; color: #111111; width: 100%; box-sizing: border-box; font-weight: 600; }
      .my-profilebox{ padding: 0; margin: 0; width: 100%; display: flex; flex-direction: column; border: #DDDDDD 1px solid;
        .head{ padding:10px 20px; margin: 0; background-color: #BECBCF; box-sizing: border-box; -webkit-box-sizing: border-box; @include common-bdy-font; color:#111111;
          font-weight: 600; border: #DDDDDD 1px solid;
        }
        .content-box{ width: 100%; padding:30px 20px; margin: 0;box-sizing: border-box; display: flex; justify-content: space-between;
          .content-sec{ padding: 0; margin: 0; width: auto; display: flex; align-items: center;
            .profile-icn{ padding:0; margin: 0; width: 45px; height: 45px;
              i{color: #174D6D; font-weight: 400; font-size: 40px;}
            }
            .name-sec{ padding: 0 0 0 20px; margin:0; @include common-bdy-font; color: #333333;
              p{ padding: 0; margin: 0; font-weight: 600;
                span{ display: block; width: 100%; margin-top:0; font-weight: normal;}
              }
            }
          }
          .button-sec{ padding: 0; margin: 0; width: auto; display: flex; align-items: center;
            button.primary-btn{ height: 40px;
              padding-left: 25px;
              padding-right: 25px;
              box-shadow: none;
              margin-left: 10px;
              font-weight: 500;
              font-size: 14px;
              letter-spacing: 1px;}
          }
        }
      }

      .other-account-block{ padding: 0;
        margin:20px -17px 0 -8px;
        display: flex;
        flex-wrap: wrap;

        a.acount-separate-block{ padding:10px; margin-bottom: 15px; margin-left: 8px; margin-right: 8px; width:calc((100% / 3) - 18px); min-height:200px; border:#DDDDDD 1px solid ;
         box-sizing: border-box; -moz-box-sizing: border-box; outline: none; text-decoration: none; cursor: pointer;
         display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
         i{font-size: 24px; color:#174D6D;}
         p{ padding:8px 0; margin: 0; @include common-bdy-font; font-weight: 600; color: #333333;}
         span{ padding: 0; margin: 0; @include common-bdy-font; color: #666666; font-weight: 400; width: 80%; text-align: center;}
        }
      }
  }
}
.account-setting-inner-wrap{
  padding: 0; margin: 0; width: 100%; display: flex;
  .account-left{ padding:0 20px 0 0; margin: 0; width: 50%; display: flex; box-sizing: border-box; flex-direction: column;}
  .account-right{ padding:0 0 0 20px; margin: 0; width: 50%; display: flex; box-sizing: border-box; flex-direction: column;}
}
.account-bottom-btn-row{ padding:20px 0 0 0; margin: 0; width: 100%; display: flex; justify-content: flex-start;
  button.yellow-btn{ width: 178px; box-shadow: none;font-size: 15px;}
}
.address-book-wrap{ padding: 0;margin:0 -17px 0 -8px;display: flex;flex-wrap: wrap;
    .address-book-block{ padding:0;
      margin-bottom: 60px;
      margin-left: 8px;
      margin-right: 8px;
      width:calc((100% / 2) - 18px);
      min-height: 200px;
      border: #DDDDDD 1px solid;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      outline: none;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content:flex-start;
      align-items:flex-start;}
      .head{ padding:10px 20px; margin: 0; width: 100%; @include common-bdy-font; font-weight: 600; background-color: #becbcf; box-sizing: border-box; color: #333333;}
      .inr-addres-box{ padding:14px 20px 20px; margin: 0; box-sizing: border-box; @include common-bdy-font; width: 100%;
        p.name{ padding: 0 0 14px 0; margin: 0; font-weight: 600; color: #333333; line-height: 24px;
          span{display: block; width: 100%;}
        }
        p.address{ padding:0 0 32px; margin: 0; font-weight: 400; color: #333333; line-height: 24px;
          span{display: block; width: 100%;}
        }
        .edit-delete-row{ padding:15px 0; margin: 0; width: 100%; position: absolute; bottom: 0;
          a{ padding:0; margin-right: 25px; text-transform: uppercase; color: #333333; font-weight: 500; text-decoration: none; cursor: pointer;}
        }
      }
}
.account-bottom-btn-row.new-address{ padding: 10px 0 0;
  .primary-btn{ width: 230px; box-shadow: none; height: 40px; font-size: 14px;}
}

.listing-wrap.wishlist{ padding-top:0px;
  .product-block{width:calc((100% / 3) - 33px);
      a.cross{
      padding: 0;
      margin: 0;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 12px;
      top: 12px;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 60%;
      outline: none;
      cursor: pointer;
      font-size:12px;
      z-index: 2;
      }
  }

}
.my-orders{
  padding: 0; margin: 0; width: 100%;
  table{ padding: 0; margin: 0; border:#CCCCCC 1px solid; width: 100%!important;
    thead{ background-color: #BECBCF; font-weight: 600;
      tr{
        td{font-weight: 600;}
      }
    }
    tr{
      td{ padding: 10px 20px; @include common-bdy-font; color: #333333; border-bottom:#CCCCCC 1px solid!important;
        button{ width: 100px!important;box-shadow: none!important; height: 40px!important;}
      }
      td.action-col{ display: flex; align-items: center; justify-content: flex-end;
        button{margin-left: 4px;margin-right: 4px;}
      }
    }
  }
}
.top-btn-heading-sec{ padding: 0; margin: 0; width: 100%; display: flex; justify-content:space-around;
  .button-section{ padding: 0; margin: 0; display: inline-flex; justify-content: flex-end; min-width: 400px;
    button{margin-left: 4px; margin-right: 4px; box-shadow: none!important;height: 40px!important; font-size: 14px!important;}
    button:last-child{margin-right:0;}
  }
}

.container-details-product-wrap{ padding: 0; margin:15px 0 0; width: 100%;
  .product-container-wrap{ padding: 0; margin:0 0 20px 0; width:calc(100% - 2px); border: #DDDDDD 1px solid;
    .head{ padding:10px 15px; margin: 0; width: 100%; display: flex; justify-content:space-between; background-color: #BFCBD0; @include common-bdy-font; color: #111111;
      font-weight: 600; font-size: 14px; box-sizing: border-box; }
      .product-container-row{ padding:20px 15px; margin: 0; box-sizing: border-box; -webkit-box-sizing: border-box; display: flex;
        border-bottom: #DDDDDD 1px solid;
        .img-block {padding: 0;margin: 0;width: 100px;height: 100px;display: flex; align-items: center;justify-content: center;overflow: hidden;
            img {box-sizing: border-box;height: 100%;object-fit: cover;}
        }
        .product-right-content{ padding:0 0 0 20px; margin: 0; width: calc(100% - 100px); box-sizing: border-box; -moz-box-sizing: border-box;
          display: flex;
          justify-content: space-between; align-items: flex-start;
          .left-sec{ padding: 0; margin: 0; display: inline-block; @include common-bdy-font; font-size: 14px!important;
            .sml-img-block{padding: 0;
              margin: 8px 0 0;
              width: 60px;
              height: 60px;
                img{ padding:0; margin: 0; width: 100%; height: auto;}
            }
            .name{padding:0 0 3px 0; margin: 0; color: #333333; font-weight: 600!important;}
            p{ padding: 0; margin: 0; font-size: 13px;}
            p.values{ display: flex; font-weight: 500; color: #333333!important; padding:0 0 3px 0;
              span{ padding:0 6px 0 0; margin: 0;  color: #999999;}
            }
          }
          .center-sec{ padding: 0; margin: 0; display: inline-block; @include common-bdy-font;
                p{ padding: 0; margin: 0; color: #999999; font-size: 13px;}
                p.values{ display: flex;  padding:0 0 3px 0; color: #333333; font-weight:500;
                span{ padding:0 6px 0 0; margin: 0;  color: #999999!important;}
              }
          }
          .right-sec{ padding: 0; margin: 0; display: inline-flex; @include common-bdy-font; height: 100%; align-items: flex-start;
            p.price{  font-weight: 600; color: #333333!important; font-size: 14px;}
          }
        }
      }
      .product-container-row:last-child{ border-bottom: #DDDDDD 0 solid;}
  }
}
.total-amount-section{ padding:0; margin:50px 0 0 0; width: 100%; box-sizing: border-box;
  .total-section{ padding: 0; margin: 0; width: 100%; border: #DDDDDD 1px solid;
    .amout-row{ padding:15px 15px; margin: 0; border-bottom:#DDDDDD 1px solid; @include common-bdy-font; color: #333333; display: flex; justify-content:space-between;
    }
    .amout-row:last-child{ font-weight: 600; border-bottom: 0px;}
  }
}
.order-id-section{ padding: 0; margin:0 0 20px 0; width: 100%; @include common-bdy-font; font-size: 15px; color: #333333;
  p{ padding:0 0 0 0; margin:0 20px 0 0; display: inline-flex; position: relative;
      span{ font-weight: 600; padding-left: 5px;}
  }
  p:before{position: absolute;
    content: "";
    height: 15px;
    width: 2px;
    background-color: #333;
    left: -11px;
    top: 2px;}
}
p:first-child:before{ display: none;}
.order-information{padding: 0; margin:45px 0 20px 0; width: 100%; @include common-bdy-font; font-size: 15px; color: #333333;
  .information-inr-wrap{ padding: 0; margin:0 -10px; display: flex; flex-direction: row; flex-wrap: wrap;
    .info-box{padding: 0;
      margin: 0 10px 20px;
      width: calc(49% - 12px);
      border: #DDDDDD 1px solid;
      .head{
        padding: 10px 15px;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: #bfcbd0;
        font-size: 15px;
        color: #333;
        font-family: Poppins,sans-serif;
        font-weight: 400;
        letter-spacing: .6px;
        color: #111;
        font-weight: 600;
        font-size: 14px;
        box-sizing: border-box;
      }
      .content-box{padding: 20px 15px; margin:0; box-sizing: border-box;
        .name{ padding:0 0 15px 0; margin: 0; font-weight: 600;
          span{display: block;}
        }
        p{ padding:0; margin: 0; font-weight: normal;}
      }
    }
  }
}
/* my account */

/* mat customize */
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #76949F!important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #76949F!important;
}
.mat-mdc-radio-button.mat-accent{
  --mdc-radio-selected-focus-icon-color: #76949F!important;
    --mdc-radio-selected-hover-icon-color: #76949F!important;
    --mdc-radio-selected-icon-color: #76949F!important;
    --mdc-radio-selected-pressed-icon-color: #76949F!important;
    --mat-radio-checked-ripple-color: #76949F!important;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color:#76949F!important;
}

.pointer-button{
  cursor: pointer;
}
.loder-btn{ position: relative;
    .loder{ position: absolute; cursor: none;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: 99;
      background-color: rgba(142, 139, 140, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      img{width:26px; height: auto;}
    }
}
/* mat customize */



/* ========= RESPONSIVE ==================================================================================================
=========================================================================================================================================
=========================================================================================================================================
=========================================================================================================================================
========================================================================================================================================= */
.mobile-menu-wrap {
  padding: 0;
  margin: 0;
  position: fixed;
  left: -320px;
  top: 0;
  width: 320px;
  height: 100vh;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
  transition: all .5s;
  display: flex;
  flex-wrap: wrap;
  -webkit-transition: all .25s;
  display: none;
  z-index: 120;
  -webkit-box-shadow: 4px 3px 30px 1px rgba(0,0,0,0.21);
-moz-box-shadow: 4px 3px 30px 1px rgba(0,0,0,0.21);
box-shadow: 4px 3px 30px 1px rgba(0,0,0,0.21);
overflow: hidden;
  .menu-top-section{ padding:0; margin:0; width: 100%;
    a.account{ padding: 10px 20px; margin: 0; height: 60px; width: 100%; box-sizing: border-box; display: flex; @include common-bdy-font; color: #fff; align-items: center; justify-content: flex-start;
      outline: none; background-color:#174D6D ;
      span{ padding:0 0 0 20px; margin: 0;}
      i{ font-size: 18px;}
    }


  }
  .menu-bottom-section{ padding: 0; margin: 0; height: calc(100% - 60px); width: 100%; position: relative;
    ul.menu-mobil{ padding: 0; margin:12px 0 0; width: 100%; list-style:none;
      li{ padding: 0; margin: 0; width: 100%;
        a{ padding:12px 12px 12px 20px; margin: 0; width: 100%; display: flex; cursor: pointer;  @include common-bdy-font; font-size: 15px; color: #333333; box-sizing: border-box;
          display: flex; align-items: center; justify-content: space-between; text-decoration: none;
          span{ padding: 0; margin: 0;}
          i{ font-size:12px;}
        }

      }
      li:hover a{ color:#174D6D;}
      li:last-child a{ border-top:#ccc 1px solid; padding-top: 20px;
        i{display: none;}
      }
      li:last-child{margin-top:10px;}
    }




    a.bottom-phone-no{
      padding:12px 12px 30px 20px; margin: 0; width: 100%; display: flex; cursor: pointer;  @include common-bdy-font; font-size: 15px; color: #333333; box-sizing: border-box;
          display: flex; align-items: center; justify-content: flex-start; text-decoration: none; position: absolute; bottom: 0; left: 0;
          span{ padding:0 0 0 12px; margin: 0;}
          i{ font-size:12px;}
    }

  }
  .mobile-secound-menu{
    padding: 0;
    margin: 0;
    width: 320px;
    height: 100%;
    background-color: #174d6d;
    z-index: 99;
    position: absolute;
    right: -320px;
    top: 0;
    flex-direction: row;
    box-sizing: border-box;
    transition: all .5s;
    display: flex;
    flex-wrap: wrap;
    -webkit-transition: all .25s;

    .menu-top-section {padding: 0;margin: 0;width: 100%;
      a.top-main-menu {
        padding: 10px 20px;
        margin: 0;
        height: 60px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        font-size: 15px;
        font-family: Poppins,sans-serif;
        font-weight: 400;
        letter-spacing: .6px;
        color: #174E6D;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        background-color: #fff;
        border-bottom: #E5E5E5 1px solid;
        i{font-size: 20px;}
        span{ font-weight: 600; color: #174E6D; padding-left:20px}
        }
    }
    .menu-bottom-section-secound{padding:20px 25px 40px;margin: 0;height: calc(100% - 60px);width: 100%;position: relative; background-color: #fff; box-sizing: border-box;
      -moz-box-sizing: border-box; overflow-y: auto;
      ul{ padding: 0; margin:0 0 25px 0; list-style: none; width: 100%;
        li{padding: 0; margin: 0; display: block;
          a{ padding:9px 0 9px 10px; margin: 0; width: 100%; text-decoration: none; font-size: 15px; font-weight: 400; @include common-bdy-font; color: #333333; cursor: pointer; display: flex;}

          ul.third-stage{ padding: 0 0 0 10px; margin:0; list-style: none; width: 100%;
            li{padding: 0; margin: 0; display: block;
              a{ padding:6px 0 6px 10px; margin: 0; width: 100%; text-decoration: none; font-size: 13px; font-weight: 400; @include common-bdy-font; color: #918d8d; cursor: pointer; display: flex;}
            }
          }
        }

        li:first-child a{ padding: 0; margin: 0; font-weight: 600; color: #174D6D;}

      }

    }
  }
  .mobile-secound-menu.show{ right: 0;}
  /* third step */
  .third-menu{
    padding: 0;
    margin: 0;
    width: 320px;
    height: 100%;
    background-color: #174d6d;
    z-index: 99;
    position: absolute;
    right: -320px;
    top: 0;
    flex-direction: row;
    box-sizing: border-box;
    transition: all .5s;
    display: flex;
    flex-wrap: wrap;
    -webkit-transition: all .25s;

    .menu-top-section {padding: 0;margin: 0;width: 100%;
      a.top-main-menu {
        padding: 10px 20px;
        margin: 0;
        height: 60px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        font-size: 15px;
        font-family: Poppins,sans-serif;
        font-weight: 400;
        letter-spacing: .6px;
        color: #174E6D;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        background-color: #fff;
        border-bottom: #E5E5E5 1px solid;
        i{font-size: 20px;}
        span{ font-weight: 600; color: #174E6D; padding-left:20px}
        }
    }
    .menu-bottom-section-secound{padding:20px 25px 40px;margin: 0;height: calc(100% - 60px);width: 100%;position: relative; background-color: #fff; box-sizing: border-box;
      -moz-box-sizing: border-box; overflow-y: auto;
      ul{ padding: 0; margin:0 0 25px 0; list-style: none; width: 100%;
        li{padding: 0; margin: 0; display: block;
          a{ padding:9px 0 9px 10px; margin: 0; box-sizing: border-box; width: 100%; text-decoration: none; font-size: 15px; font-weight: 400; @include common-bdy-font; color: #333333; cursor: pointer; display: flex;}
        }
        li:first-child a{ padding: 0; margin: 0; font-weight: 600; color: #174D6D;}
      }

    }
  }
  .third-menu.show{ right: 0;}
  /* third step */
}
.mobile-menu-wrap.show {left: 0;}
.mobile-menu-gray-bg{ padding: 0; margin: 0; position: fixed; z-index: 100; top:0; width: 100%; height: 100vh; background-color: #939393; opacity: 0.9; display: none;}

/* mobile-search */
.mobile-search{ padding:5px 20px 20px;
  margin: 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #fff;
  right: 0;
  bottom: 0;
  display: none;
  a.top-sec{ padding:0 0 15px 0; margin: 0; display: flex; align-items: center; justify-content: flex-start; box-sizing: border-box; cursor: pointer; outline: none; border: 0;
    height: 60px; width: 100%;
    i{ padding: 0; margin: 0; font-size: 20px; color: #174E6D;}
    span{ padding: 0 0 0 12px; margin: 0; @include common-bdy-font;  color: #174E6D; font-weight: 600; font-size: 15px;  }
  }
  .mobile-search-section{ padding: 0; margin: 0; width: 100%;
    .head-sec{ padding: 0; margin:25px 0 15px; width: 100%; display: flex; align-items: center; justify-content: space-between; @include common-bdy-font; font-size: 15px; color: #333333; font-weight:500;
      i{font-size: 13px;}
      span{ padding: 0; margin: 0;}
      a{padding: 0; margin: 0; color: #333333; text-decoration: none;}
    }
   .mega-search-innerwrap {
      margin: 0 auto;
      width:100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .input-outerbox {
        padding: 6px 100px 6px 50px;
        width: 100%;
        border: #ccc 1px solid;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        a.search-con {
          padding: 0;
          margin: 0;
          position: absolute;
          left: 15px;
          width: 30px;
          color: #174e6d;
          border: 0; outline: none;
      }
      input {
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #fff;
        display: block;
        height: 30px;
        border: 0;
        outline: none;
        font-size: 15px;
        color: #333;
        font-family: Poppins,sans-serif;
        font-weight: 400;
        letter-spacing: .6px;
        font-size: 14px;
        }
      }


      .mega-search-content{ overflow-y: auto;
        padding: 0;
        margin: 0;
        width: 100%;
        position: relative;
        height: calc(100vh - 220px);

        .search-row{ padding: 0; margin:0 0 15px 0; width: 100%; display: flex; align-items: center; justify-content: flex-start;
          .image-box{ padding:0; margin: 0; width: 70px; height: 70px; display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
              img{
                box-sizing: border-box;
                height: 100%;
                object-fit: cover;
                }
            }
          .right-name-section{ padding: 0; margin: 0 0 0 20px; width:calc(100% - 90px); display: flex; flex-direction: column;
            p{ padding:0; margin:0 0 6px 0; @include common-bdy-font; font-weight: 500; font-size: 15px;  }
            span{ padding: 0; margin: 0; @include common-bdy-font; font-size:14px; text-transform: uppercase; color: #999999;}
          }
        }

      }
    }
  }
}

/* mobile-search */

/* order process page */
.order-process-container{ padding:100px 20px; margin: 0; width: 100%; box-sizing: border-box; background-color: #EEECE9; display: flex; align-items: center; justify-content: center;
    .order-process-box{ padding:60px 150px; margin: 0; box-sizing: border-box; position: relative; display: flex; align-items: center; justify-content: center; background-color: #fff;
      max-width: 960px; flex-direction: column;
      .icon-part{ padding: 0; margin: 0; position: absolute; width: 115px; height: 115px; right:-50px; top:-40px;
       i{ color:#25AC6B ; font-size:110px;}
      }
      h2{ padding: 0; margin:0 0 28px 0; @include heading-bdy-font; color: #111111; font-weight: 600; font-size: 40px;}
      h4{padding: 0; margin:0 0 35px 0; @include heading-bdy-font; color: #25AC6B; font-weight: 400; font-size: 24px;}
      p{ padding:0; margin:0 0 40px; @include common-bdy-font; color: #333333; font-size: 15px; text-align: center;}
      .border-row{ margin: 0; padding: 0; display: flex; align-items: center; justify-content: center;

      }
      button{box-shadow:none}
    }

}
/* order process page */
.mobile-filter-section{ padding:5px 20px; margin: 0; height: 50px; width: 100%; background-color: #fff; position: sticky; box-sizing: border-box; top:67px; z-index: 99; display: none;
  -webkit-box-shadow: 0px 6px 16px -3px rgba(0,0,0,0.21);
-moz-box-shadow: 0px 6px 16px -3px rgba(0,0,0,0.21);
box-shadow: 0px 6px 16px -3px rgba(0,0,0,0.21); border-top: #DDDDDD 1px solid;
ul{ padding: 0; margin: 0; display: flex; width: 100%; list-style: none;
  li{ padding: 0; margin: 0; width: 50%; border-right: #DDDDDD 1px solid;
      a{ padding: 0; margin: 0; @include common-bdy-font; font-size: 14px; color: #174D6D; font-weight: 500; border: 0; outline: none; cursor: pointer; display: flex;
         align-items: center; justify-content: center; height: 100%; width: 100%;
          i{font-size: 16px; color: #174D6D; padding-right:12px;}
        }
  }
  li:last-child{border-right:0px;}
}
}


/* *** Mobile Filter *** */
.mobile-filter-iner-sec{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;

    .head-sec{ padding: 16px 20px 20px;
      margin: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      z-index: 9;
      width: 100%;
      background-color: #fff;
      border-bottom: #ccc 1px solid;

      a.left{ padding: 0; margin: 0; display: flex; align-items: center; border: 0; outline: none;
        i{ font-size: 18px; color: #174E6D; padding-right: 15px;}
          span{ padding: 0; margin: 0; @include common-bdy-font; font-size: 15px; font-weight: 600;}
      }
      a.right{ padding: 0; margin: 0; color: #666666; @include common-bdy-font; font-size: 13px; text-transform: uppercase; border: 0; outline: none;}
    }

    .filter-menu-section{ padding: 60px 20px 20px 20px;
      box-sizing: border-box;
      margin: 0;
      width: 100%;
      height: 95vh;
      background-color: #fff;
      overflow-y: auto;
      .search-box-section{
        .input-outerbox {
          padding:6px 20px 6px 40px;
          width: 100%;
          border: #ccc 1px solid;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          a.search-con {
            padding: 0;
            margin: 0;
            position: absolute;
            left: 15px;
            width: 30px;
            color: #174e6d;
            border: 0;
            outline: none;
        }
                input {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    background-color: #fff;
                    display: block;
                    height: 30px;
                    border: 0;
                    outline: none;
                    font-size: 15px;
                    color: #333;
                    font-family: Poppins,sans-serif;
                    font-weight: 400;
                    letter-spacing: .6px;
                    font-size: 14px;
                }
      }


      }

      ul.menu-list{
        padding: 0; margin: 0; list-style: none;
        li{ padding: 0 0 6px 0; margin: 0; width: 100%; display: flex; align-items: center; justify-content: space-between;
          span{color: #999999;}
        }
      }
      .mat-accordion{ width: 100%;}
      .mat-expansion-panel-header-title{ @include common-bdy-font; font-size:14px; color: #174E6D;}
      .mat-expansion-indicator::after{padding: 4px!important; color: #174e6d; }
      .mat-expansion-panel-body {padding:0 5px 12px!important;}
      .mat-expansion-panel-header{padding: 0 5px!important;}
      .mat-expansion-panel-spacing {margin: 0 0 !important;}
      .mat-expansion-panel{background-color: transparent !important;}
      .mat-expansion-panel:not([class*=mat-elevation-z]){ box-shadow: none!important; border-bottom: #ccc 1px solid; border-radius: 0px;}
      .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {background:#fff;}
      .mat-mdc-checkbox{height: 28px; display: flex;}
    }

    .apply-btn-section{padding: 20px 20px 20px;margin: 0;width: 100%;position: fixed;bottom: 0;z-index: 9; box-sizing: border-box;
        button.primary-btn{width: 100%;box-shadow: none;height: 40px;font-size: 14px;}
    }
    .search-box-section{padding-left: 10px;padding-right: 10px;box-sizing: border-box;padding-bottom: 20px;}
}
/* *** Mobile Filter *** */

/* *** Mobile Sort *** */
.mobile-sort-sec{
  padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background-color: #939393;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    .sort-iner-sec{ padding:20px; margin: 0; box-sizing: border-box; bottom: 0; left:0; right: 0; background-color: #fff; z-index: 9; position: fixed;
      .head{ padding:0 10px 10px; margin: 0; width: 100%; @include common-bdy-font; font-weight: 600; font-size: 15px; color: #174E6D;  box-sizing: border-box; text-transform: uppercase;}
      ul{ padding: 0; margin: 0; width: 100%; list-style: none;
          li{padding:0; margin: 0; width: 100%; display: flex; color: #333333; @include common-bdy-font; font-size: 14px;}
      }
    }
}
/* *** Mobile Sort *** */
.order-details-top .right-section .chose-color{
  .owl-theme .owl-nav{
    margin-top: 0;
    position: absolute;
    top: 35%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    .owl-prev, .owl-next{
      pointer-events: all;
      border-radius: 50% !important;
      color: #333 !important;
      background-color: #fff !important;
      height: 30px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      i{font-size: 18px;}
      }
      .owl-prev{
        i{padding-left: 6px;}
      }
  }

}
.pop-box a.cross-btn{display: none;}
.bcipottery-four-img{padding-bottom: 60px!important;}
.order-details-top .right-section .chose-color{
  .owl-carousel.owl-drag .owl-item{width: 105px!important;}
  .owl-carousel .owl-stage {flex-wrap: nowrap!important;display: flex!important;}
}

.mobile-loadmore{ padding:0 20px 20px; margin:0; box-sizing: border-box; width: 100%; display: none;
  a{padding: 0; margin: 0; display: flex; justify-content: flex-end; @include common-bdy-font; font-size: 14px; cursor: pointer; align-items: center;
    width: 100%;
    i{ padding-right: 10px;}
  }
}

.bcipottery-wraper{min-height:calc(100vh - 224px);}
.listing-wrap{min-height:600px;}
.order-details-top{min-height: 800px;}
.cart-wrap{min-height:800px;}
.my-account-wrap{min-height:800px;}
.bcipottery-home-custom-wrapper{min-height: 800px!important;}


.custom-comment-wrap{
  height: 212px;
  line-height:19px;
  -webkit-line-clamp:13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px!important;
  margin-bottom: 30px !important;
  position: relative;
  }
  .custom-comment-wrap.full-content{height:auto;}
  .custom-comment-wrap + a{ position: absolute; right: 0; bottom:20px; font-size: 14px; color:#333; cursor: pointer; text-decoration: underline;}
.custom-all-page-min-height{min-height: 800px;}

.product-container-row.custom{
  .product-right-content{
    .left-sec{width: 45%;
      .sml-img-block{margin: 6px 0 8px; overflow: hidden;
        img{object-fit: cover;}
      }
    }
  }
}

.pallet-type{
  .mdc-form-field>label{
    /* Your custom styles here */
    color: #333;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    letter-spacing: .8px;
    font-size: 14px;
  }
}

.inner-head{
  a.combinable {
    color: #637c8a!important;
    cursor: pointer!important;
  }

  a.combinable:hover {

    color: #174E6D!important;
    cursor: pointer!important;
  }
}



/* ********* @media ********* */
@media only screen and (max-width: 1500px){
  .main-header .mega-menu .menu-tab-iner-wrap {
    margin: 0 auto;
    width: 100%;
    padding-left:148px;
    padding-right: 200px;box-sizing: border-box;}
    .main-header .mega-menu .menu-tab-iner-wrap .mega-menu-box .mega-innerwrap .left-section ul:first-child {padding-right: 20px;}
    .main-header .mega-menu .menu-tab-iner-wrap .mega-menu-box .mega-innerwrap .left-section ul {padding: 0 20px 0 0;}
    .main-header .mega-menu .menu-tab-iner-wrap .mega-menu-box .mega-innerwrap .left-section {padding: 0 20px 0 0;width: calc(100% - 300px);}
    .main-header .mega-menu .menu-tab-iner-wrap .mega-menu-box .mega-innerwrap .right-image-block {width: 300px;height: 300px;}
    .categories-sec .categories-sec-img-block {height: 350px;}
    .arrival-sec {padding: 90px 0 0;}
    .arrival-sec .arrival-img-block{height: 595px;}
    .section-head {padding: 64px 20px 40px;}
    .container-xl {padding: 0 20px;box-sizing: border-box;margin: 0 auto;width: 100%!important;}
    .top-steper-section .inner-section {width: 100%;padding: 0 20px;}
    .order-details-top .left-section .details-product-block{height: 550px;}
    .listing-wrap.wishlist{
      .product-block {width: calc(30% - 0px);}
    }
}
@media only screen and (max-width: 1366px){
  .main-header .mega-menu .menu-tab-iner-wrap {margin: 0 auto;width: 100%;padding-left:148px;padding-right:200px;}
  .main-header .mega-menu .menu-tab-iner-wrap .mega-menu-box .mega-innerwrap{ width: 100%;}
  .main-header .mega-menu .menu-tab-iner-wrap .mega-menu-box .mega-innerwrap .left-section ul:first-child {width: 300px;height: auto;min-height: 300px;}
  footer .footer-inner-section {width: 100%;}
  .product-block .product-img-sec{height: 300px;}
  .details-product-block h4{ padding: 0;font-size: 30px;}
  .order-information .information-inr-wrap .info-box{width:calc(95% / 2);}
  .cart-wrap .checkout-left {width: calc(100% - 435px);}
}
@media only screen and (max-width: 1280px){
  .product-block .product-img-sec{height: 280px;}
  .product-block {margin: 0 15px;width: calc(33% - 30px);}
  .order-details-top .left-section .details-product-block{ height: 400px;}
  .quantity-per-plate .qunt-block .qunt-sec-body .qunt-sec {width: 100%;}
  .order-details-top .right-section .button-row{flex-wrap: wrap;}
  .quantity-per-plate.add-color-quantity .qunt-sec-body button{font-size: 12px;}
  .order-details-top .right-section .button-row button {width: 100%;margin-bottom: 12px;}
  .details-product-block {margin: 20px 0 0;}
  .features-section {padding: 50px 0 0;}
  .order-details-top .right-section .table-tab-section .set-count-section .left, .order-details-top .right-section .table-tab-section .set-count-section .right{
     margin-bottom: 15px;
  }
    .order-details-top .right-section .table-tab-section .set-count-section .left p, .order-details-top .right-section .table-tab-section .set-count-section .right p {
  padding: 0 12px 0 0;min-width: 125px;}
  .order-details-top .right-section .table-tab-section .set-count-section{flex-wrap: wrap;}
  .cart-wrap .cart-left {margin: 0 20px 0 0;width: calc(100% - 420px);}
  .cart-wrap .cart-right {padding: 0;margin: 0;width: 400px;}
  .product-cart-wrap .product-cart-row .right-text-section .top-name-sec .name-sec {padding: 0 12px 0 0;margin: 0;width: 40%;}
  .product-cart-wrap .product-cart-row .right-text-section .top-name-sec .size-sec {width: 60%;}
  .product-cart-wrap .product-cart-row .right-text-section .top-name-sec .size-sec p span {display: flex; min-width: 116px;}
  .listing-wrap.wishlist .product-block {width:calc((100% / 2) - 33px)}
  .order-information .information-inr-wrap .info-box {width:100%;}
  .inner-head h2{ font-size:34px!important;}
  .order-process-container .order-process-box {padding: 40px 50px; max-width: 85%;}
  .account-setting-inner-wrap .account-left {padding: 0 10px 0 0;}

}

@media only screen and (max-width: 1024px){
  .my-account-wrap .account-right-section h4{font-size: 20px;}
  .product-block .product-img-sec{height: 200px;}
  .product-cart-wrap .cart-total-btn-row .right-sec a:first-child {padding-right: 10px;}
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec{flex-wrap: wrap;}
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec {padding: 10px 0 0;margin: 0;display: inline-flex;}
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec a:first-child{padding-left: 0px;}
  .product-cart-wrap .cart-total-btn-row .right-sec a:first-child {padding-right: 20px;}

}
@media only screen and (max-width: 992px){
  .mega-menu{display: none;}
  .top-gray-header{display: none;}
  .main-header{margin-top: 0px; padding-top:0px;}
  .mobile-menu-wrap {display: flex;}
  .mobile-menu-wrap.show + .mobile-menu-gray-bg{display: flex;}
  .main-header .header-inner-sec .header-heading{display: none;}
  .main-header .header-inner-sec .header-left-sec ul li:nth-child(2){display: none;}
  .main-header .header-inner-sec a.main-logo {width: 80px; margin-right: auto;}
  .main-header{
    .header-inner-sec{
      a.mobile-toggle{ width: 40px; height: 40px; margin-right: 30px; display: flex;
        i{ font-size:34px; color: #174D6D;}
      }
  }
 }
 .main-banner .banner-inner-sec{height: auto;}
 .mobile-search{display: flex; flex-wrap: wrap; flex-direction: column;}
 .main-header .header-inner-sec {padding: 5px 20px;}
 .main-banner .banner-inner-sec .banner-text-area{top: 118px;}
 .main-banner .banner-inner-sec .banner-text-area h1 {font-size: 50px;}
 .main-banner .banner-inner-sec .banner-text-area p{font-size: 16px;}
  footer .footer-inner-section .top-section{display: none;}
  .top-section{display: none;}
  footer .footer-inner-section .footer-mobile{display: flex;}
  footer {padding: 30px 20px;}
  footer .footer-inner-section .bottom-section {margin: 30px 0 0; flex-direction: column;}
  footer .footer-inner-section .bottom-section .right {order: 1; width: 100%;}
  footer .footer-inner-section .bottom-section .left {order: 2; width: 100%; padding: 0;}
  footer .footer-inner-section .bottom-section .left ul{flex-direction: column; padding-top:20px}
  footer .footer-inner-section .bottom-section .right ul li:first-child{padding-left: 0px;}
  footer .footer-inner-section .bottom-section .right ul {padding: 0;margin: 0;width: 100%;display: flex;justify-content: flex-start; }
  .login-body .login-box {position: fixed;top: 0;bottom: 0;z-index: 99; margin: 0 0 0 0;}
  .login-body .login-box {position: fixed;top: 0;bottom: 0;z-index: 99;margin: 0 0 0 0;width: 100%;box-sizing: border-box;padding: 80px 80px 50px;}
  .login-body .login-box .login-box-inner {height: calc(100% - 30px); overflow: auto;
    .row-cus{margin:0;}
    .col-50 {width: 50%;padding-left:15px;padding-right:15px;}
  }
  .login-body .login-box a.login-cross{display: flex;}
  #appHeader{position: fixed;top: 0;z-index: 999;background: #fff; width: 100%;}
  .shorter-section{display: none;}
  .listing-chiper-section{display: none;}
  .main-header .header-inner-sec a.back-arrow{display: flex;}
  .product-block .mob-button-row{display: flex;}
  .product-block .product-img-sec:hover button.order-product{display: none;}
  .inner-head.for-mobile-inner-head{ padding: 25px 0 0;}
  .top-steper-section{display: none;}
  .page-stepper-section{display: none;}
  .mobile-filter-section{display: flex;}
  .mobile-filter-iner-sec.show{display: flex;}
  .mobile-sort-sec.show{display: flex;}
  .order-details-top {display: flex;flex-wrap: wrap;flex-direction: column;}
  .order-details-top .left-section {width: 100%;}
  .order-details-top .right-section {padding: 0;margin: 40px 0 0;width: 100%;}
  .order-details-top .left-section .details-product-block {height: 400px;max-width: 600px;margin-left: auto;margin-right: auto;}
  .cart-wrap .cart-left {margin: 0 20px 0 0;width: 100%;}
  .cart-wrap .cart-right {width: 100%;}
  .cart-wrap {flex-direction: column;}
  .new-container-add-sec ul {flex-wrap: wrap;}
  .my-account-wrap .account-right-section {width: calc(100% - 188px);}
  .my-account-wrap .left-menu-list {width: 250px;}
  .my-account-wrap {flex-direction: column;}
  .my-account-wrap .left-menu-list {width: 100%;}
  .my-account-wrap .account-right-section {margin: 40px 0 0;padding: 0;width: 100%;}
  .my-account-wrap .account-right-section .my-orders {overflow-x: auto;}
  .cart-wrap .checkout-left {width: 100%;margin-bottom: 50px;}
  .inner-head {padding: 25px 0 20px;}
  .cart-wrap .checkout-left {margin: -20px 0 0 0;}
  .cart-wrap .cart-right {margin-top: 35px;}
  .order-process-container .order-process-box p{text-align: center;}
  .my-account-wrap .account-right-section .other-account-block a.acount-separate-block{width: calc(50% - 20px);}
  footer .footer-inner-section{
    display: flex;flex-direction: column;
    .bottom-section{order: 2;}
    .footer-mobile{order: 1;}
  }
  .mega-search{top: 0;}
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec {padding: 0 0 0;}
  .mobile-loadmore{display: flex;}
  .custom-all-page-min-height{min-height: 800px; padding-top: 68px;}

}

@media only screen and (max-width: 768px){
  .my-account-wrap .account-right-section .my-profilebox .content-box{
    flex-direction: column; padding: 30px 20px 15px;
    .content-sec{width: 100%!important;}
    .button-sec{width: 100%!important; padding: 25px 0 0;flex-direction: column;
      button{ width: 100%; margin-bottom: 8px; padding-left: 0!important; margin-left: 0px!important;}
    }
   }
  .my-account-wrap .account-right-section .other-account-block a.acount-separate-block {width: calc(100% - 23px);}

  .colection-sec {flex-direction: column;}
  .colection-sec .collection-img-sec {margin: 0 15px 20px;width: calc(100% - 30px);height: 420px;}
  .arrival-sec {padding:40px 0 0;margin: 0;width: 100%;display: flex;flex-direction: column;}
  .arrival-sec .arrival-img-block:first-child {margin-top: 0;}
  .categories-sec {flex-direction: column;}
  .categories-sec .categories-sec-img-block {margin: 0 15px 20px;width: calc(100% - 30px);}
  .ustomers-say {flex-direction: column;}
  .ustomers-say .ustomers-comment-block {padding: 0;margin:0 15px 20px;width: calc(100% - 30px);}
  .ustomers-say {padding: 10px 0 20px;}
  .your-favorites {flex-direction: column;}
  .product-block {margin: 0 15px 30px;width: calc(100% - 30px);border: #DDDDDD 1px solid; max-width: 100%;}
  .main-banner {margin: 0 0 30px 0;}
  .listing-wrap{
    .product-block {width: calc(50% - 32px);}
  }
  .inner-head h2{font-size: 24px!important;}
  .pop-box{ width: 90%; min-width: auto; position: relative;
      a.cross-btn{ padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
        position: absolute;
        right: -10px;
        top: -18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #174e6d;
        background-color: #fff;
        border-radius: 50%;
        border: #174e6d 1px solid;

      }
  }
  .order-details-top .right-section .table-tab-section .set-count-section .left p, .order-details-top .right-section .table-tab-section .set-count-section .right p {
    padding: 0 12px 0 0;min-width: auto;}
  .order-details-top .right-section .table-tab-section .set-count-section .left, .order-details-top .right-section .table-tab-section .set-count-section .right{margin-bottom: 0px;}
  .new-container-add-sec ul li .primary-base-blue-btn {width: 100%;margin-bottom: 8px;}
  .new-container-add-sec ul li {padding: 0;margin: 0;width: 100%;}
  .inner-head {padding: 20px 0 12px; }
  header{border-bottom: #ccc 1px solid;}
  .cart-wrap .cart-right{ margin-top: 35px;}
  .my-account-wrap{flex-direction: column;}
  .my-account-wrap .left-menu-list{ width: 100%;}
  .my-account-wrap .account-right-section{margin:40px 0 0; padding: 0; width: 100%;
    .my-orders{overflow-x: auto;}
  }
  .inner-head h2{font-size:22px!important;}
  .top-btn-heading-sec{flex-direction: column;}
  .top-btn-heading-sec .button-section {min-width: 100%; flex-direction: column;}
  .top-btn-heading-sec .button-section button{margin-left: 0; margin-right: 0; width: 100%; margin-bottom: 10px;}
  .container-details-product-wrap .product-container-wrap .product-container-row .product-right-content{flex-direction: column; padding: 0 0 0 15px; width: calc(100% - 70px); }
  .container-details-product-wrap .product-container-wrap .product-container-row .product-right-content .left-sec{width: 100%;}
  .container-details-product-wrap .product-container-wrap .product-container-row .product-right-content .center-sec{width: 100%;}
  .container-details-product-wrap .product-container-wrap .product-container-row .product-right-content .right-sec{width: 100%; margin-top: 10px;}
  .container-details-product-wrap .product-container-wrap{width: calc(100% - 2px);}
  .total-amount-section .total-section{width: calc(100% - 2px);}
  .total-amount-section{margin: 40px 0 0;}
  .container-details-product-wrap .product-container-wrap .product-container-row .img-block{width: 65px;height: 65px;}
  .my-account-wrap .account-right-section h4{font-size: 20px;}
  .order-information .information-inr-wrap .info-box {width: 100%;}
  .cart-wrap .checkout-left{
    .mat-expansion-panel-header-title{font-size: 20px;}
    .address-wrap .btn-row button{width: 100%;}
  }
  .order-process-container .order-process-box{
    padding: 85px 50px 40px;
    .icon-part{left: calc(50% - 55px); top: -55px;}
  }
  .order-process-container .order-process-box .border-row { text-align: center;}
  .mega-search{top:0;}

 .row-cus {padding: 0!important;
    .mat-mdc-form-field-error {font-size: 11px!important;}
    .mat-mdc-form-field{padding-bottom: 10px!important;}
  }
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{padding: 0 16px 0 0!important;}
  .address-wrap .address-box .edit-sec{ position: relative; align-items: self-end; justify-content: flex-end;
    span.defult-address {position: absolute;right:0;top:0; width: 90px;}
  }
  .cart-wrap .cart-left h5{font-size:18px;}
  .cart-wrap .cart-right h5{font-size:18px;}
  .bcipottery-wraper{min-height:calc(100vh - 424px);}
  .listing-wrap{min-height:700px;}
  .order-details-top{min-height:800px;}
  .cart-wrap{min-height:800px;}
  .my-account-wrap{min-height:800px;}
  .bcipottery-home-custom-wrapper{min-height:800px!important;}

  }


@media only screen and (max-width: 600px){
  .main-banner .banner-inner-sec {height: 300px;
    img{ height: 300px;}
  }
  .main-banner .banner-inner-sec .banner-text-area h1 {font-size: 36px;}
  .primary-base-btn{font-size: 14px; height: 40px;}
  .main-banner .banner-inner-sec .banner-text-area {top: 100px;padding: 0 20px;box-sizing: border-box;}
  .section-head h2 {font-size: 24px;font-weight: 600;}
  .section-head {padding:10px 20px 40px;}
  .colection-sec .collection-img-sec{height: 350px;}
  .colection-sec .collection-img-sec h4 {font-size: 24px;bottom: 15px;}
  .see-all {padding: 25px 0 0;}
  .arrival-sec {padding: 40px 0 0;}
  .arrival-sec .arrival-img-block {padding: 20px 0 0;margin: 0 15px;width: calc(100% - 30px);height:350px;}
  .arrival-sec .arrival-img-block .text-sec h3 {font-size: 24px;}
  .categories-sec .categories-sec-img-block {margin: 0 15px 20px;width: calc(100% - 30px);height: 350px;}
  .categories-sec .categories-sec-img-block .text-section {padding: 6px 20px;height: 40px;font-size: 20px;}
  .login-box-inner{
    .pt-30 {padding-top: 0px!important;}
  }
  .login-body .login-box .login-box-inner {margin: 30px 0 0; height: calc(100% - 30px); padding-right: 18px;}
  .login-body .login-box {padding: 80px 20px 50px; }
  .login-body .login-box .login-box-inner {
    .row-cus{margin: 0;}
    .col-50 {width:100%;padding-left: 0;padding-right: 0;}
    .col-full {width: 100%;padding-left: 0;padding-right: 0;}
  }
  .listing-wrap{
    .product-block {width:100%; margin:0 8px 30px; }
  }
  .main-header .header-inner-sec a.main-logo {width: 60px;margin-right: auto;}
  .pop-box .pop-head{font-size: 16px;}
  .order-details-top .right-section .table-tab-section .set-count-section .left p, .order-details-top .right-section .table-tab-section .set-count-section .right p {
    padding: 0 12px 0 0;
    min-width: 125px;
}
.order-details-top .right-section .table-tab-section .set-count-section .left, .order-details-top .right-section .table-tab-section .set-count-section .right {
  margin-bottom: 10px;
}
.order-details-top .right-section .value-row {
  padding: 0 0;
  // flex-wrap: wrap;
  // flex-direction: column;
}
.order-details-top .right-section .value-row p {display: inline-flex;width: 100%;padding-left: 8px;}
.order-details-top .right-section .table-tab-section .chart-table table {min-width: 600px;}
.order-details-top .right-section .table-tab-section .chart-table {margin: 18px 0 0;width: 100%;overflow-x: scroll;}
.order-details-top .right-section .right-head h4{font-size: 24px;}
.features-section .features-block .f-block{padding: 20px 15px; font-size: 14px;flex-direction: column;justify-content: center;align-items: center;text-align: center;}
.features-section .features-block .f-block i {padding-right: 0;margin: 0 0 10px 0;font-size:26px;}
.container-details .left-bar {padding: 0;margin: 0;width: 100%;}
.container-details .truck-section {width: 100%;display: flex;justify-content: center; margin-top: 15px; order: 2;
  img{width: 95%; height: auto;}
}
.container-details .palate-count-sec{order: 1;}
.order-details-top .right-section .primary-base-btn.add-container {height: 48px;}
.product-cart-wrap .product-cart-row .right-text-section .top-name-sec {margin: 0 0 15px;width: 100%;display: flex;flex-direction: column;}
.product-cart-wrap .product-cart-row .right-text-section .top-name-sec .name-sec {padding: 0;margin: 0;width: 100%;}
.product-cart-wrap .product-cart-row .right-text-section .top-name-sec .size-sec {width: 100%;}
.product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec{flex-wrap: wrap;}
.listing-wrap.wishlist .product-block {width:100%}
.account-setting-inner-wrap{flex-direction: column;}
.account-setting-inner-wrap .account-left{ width: 100%; padding: 0;}
.account-setting-inner-wrap .account-right{ width: 100%; padding: 0;}
.my-account-wrap .account-right-section h4{font-size: 20px;}
.account-bottom-btn-row button.yellow-btn {width: 100%;}
.account-bottom-btn-row {padding: 10px 0 0;}
.address-new-pop-inner{
  .col-50{width: 100%;}
}
.pop-box.plate-count .bottom{flex-direction: column;}
.pop-box.plate-count .bottom.blk .primary-base-btn {border: #ccc 1px solid;margin-top: 10px;}
.pop-box.plate-count .bottom button{width: 100%;}
.pop-box.plate-count .top {flex-direction: column;}
.pop-box.plate-count .top .left .img-sec{width: 100%; height: 300px;}
.pop-box.plate-count .top .left, .pop-box.plate-count .top .right{ width: 100%;}
.pop-box.plate-count{
  .primary-base-btn{ border: #ccc 1px solid; margin-top: 10px; }
}
.pop-box.plate-count .top .left .img-sec {height: 200px;}
.pop-box.plate-count .top .right {margin-top: 20px;}
.address-book-wrap .address-book-block{width: calc(100% - 24px);}
.account-bottom-btn-row.new-address .primary-btn {width: 100%;}
.container-details-product-wrap .product-container-wrap .product-container-row .product-right-content .center-sec p span{width: 80px; min-width: 80px; padding-right: 6px;}
.container-details-product-wrap .product-container-wrap .product-container-row .product-right-content .left-sec p span{width: 80px; min-width: 80px; padding-right: 6px;}
.address-wrap .address-box {padding: 20px 10px 20px 10px;}
.cart-wrap .checkout-left .address-wrap .btn-row button {padding-left: 20px;padding-right: 20px;}
.address-wrap .address-box .radio-sec {padding: 0; margin: 0;}
.shipping-method .shipping-form-section{
  .row-cus {padding: 0 0 2px;
    .col-50 { width: 100%!important;}
  }
}

.shipping-method .shipping-form-section .btn-row button {padding-left: 20px;padding-right: 20px;box-shadow: none;width: 100%;}
.payment-method .btn-row button {padding-left: 20px;padding-right: 20px;width: 100%; box-shadow: none;min-width: 100%;}
.payment-method {padding: 0 0;}
.payment-method .notice{padding: 0 0 20px;}

.order-process-container .order-process-box{padding: 85px 16px 26px;max-width: 95%;}
.order-process-container .order-process-box h2 {margin: 0 0 4px;font-size: 24px;text-align: center;}
.order-process-container .order-process-box h4 {margin: 0 0 24px;font-size: 16px;}
 .order-process-container .order-process-box .border-row { text-align: center;}
 .product-block .product-img-sec img {height: 100%;width: 100%;}
 .product-block .product-img-sec{ max-height: 350px; min-height:300px;}
 .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec {padding:10px 0 0;}
 .product-cart-wrap .cart-total-btn-row {height:auto;display: flex;flex-direction: column;}
 .product-cart-wrap .cart-total-btn-row .left-sec{height: 50px; width: 100%; order: 2;}
 .product-cart-wrap .cart-total-btn-row .right-sec{ justify-content: center;  width: 100%; order:1;}
 .product-cart-wrap .cart-total-btn-row .right-sec a {padding: 0 20px;}
 .product-cart-wrap .product-cart-row .right-text-section .top-name-sec .name-sec .sml-img-block {margin: 8px 30px 8px;}
 .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec {padding: 0;}
 .cart-wrap{margin:24px 0 0;}
 a.filter-block{
    span{display: flex;flex-wrap: nowrap;white-space: nowrap;align-items: center;}
 }
 a.filter-block .filter-drop{left: -40px;}
 .order-details-top{min-height:600px;}
 .cart-wrap{min-height:600px;}
 .my-account-wrap{min-height:600px;}
 .mobile-filter-section{ top:54px;}
 .custom-all-page-min-height {min-height: 800px;padding-top: 54px;}
 .pop-box .add-order-quentity-box.color-view .row .choose-img-block{
  width: calc(50% - 20px);
 }
 .pop-box {width: 100%;}
 .order-details-top .right-section .chose-color .chose-quantity{ flex-wrap: wrap;}
 a.filter-block p {padding: 0;margin: 0;display: flex;align-items: center;}

}

.mat-mdc-snack-bar-label {
  font-size: large !important;
}

@media only screen and (max-width: 480px){
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec {padding:10px 0 0 0;}
  .main-header .header-inner-sec a.mobile-toggle i {font-size: 28px;}
  .main-header .header-inner-sec a.mobile-toggle {width: 30px;height: 30px;margin-right: 14px;}
  .login-body .login-box .login-box-inner button {width: 100%;font-size: 13px;padding: 0 5px;}
  .pop-box .add-order-quentity-box .addcolor-row .left-imgblock .img-block{width: 60px;height: 60px;}
  .pop-box .add-order-quentity-box .addcolor-row .left-imgblock p{padding: 0 0 0 10px}
  .primary-btn {padding: 0 20px;margin: 0;height: 40px;font-size: 14px;}
  .order-details-top .right-section .table-tab-section .cunt-btn-row button {height: 40px;width: 100%;}
  .quantity-per-plate.add-color-quantity .qunt-sec-body button {font-size: 14px;width: 90%;}
  .order-details-top .right-section .button-row .primary-base-btn.total-set {font-size: 14px;height: 47px;}
  .details-product-block h4 {font-size:20px;}
  .order-details-top .right-section .right-head h4 {font-size: 20px;}
  .details-product-block {margin: 0 0 0;}
  .order-details-top {padding: 25px 0 30px;}
  .order-details-top .right-section .chose-quantity h5{ font-size: 16px!important;}
  .product-cart-wrap .product-cart-row .left-img-block{width: 80px;height: 80px;}
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec{padding: 15px 0 0 0;}
  .product-cart-wrap .product-cart-row .right-text-section .bottom-qnt-sec .edit-del-btn-sec a:first-child{padding-left: 5px;}
  .container-details .palate-count-sec{flex-wrap: wrap; margin-top: 15px;}
  .address-book-wrap .inr-addres-box{font-size: 14px;}
  .address-book-wrap .inr-addres-box p.address {padding: 0 0 30px;}
  .address-book-wrap .inr-addres-box p.name {padding: 0 0 8px;}
  .my-orders table tr td {padding: 8px 12px;font-size: 14px;}
  .address-wrap .address-box {padding: 20px 10px 20px 10px; font-size: 15px;}
  .cart-wrap .checkout-left .mat-expansion-panel-header-title {font-size: 18px;}
  .address-wrap .address-box .edit-sec span.defult-address {padding: 4px 2px;}
  .order-process-container .order-process-box .border-row {width: 100%;
    button{ width: 100%;}
  }
  .your-favorites{
    .owl-carousel .owl-stage {
      height: auto !important;
      flex-direction: row;
      display: flex;
      }
      .owl-carousel.owl-drag .owl-item {max-width: 380px !important;min-width: 320px;}
  }
  .bcipottery-four-img {padding-bottom: 30px !important;}

    .bcipottery-text-heading-titel{
      p{font-size:18px!important;}
    }
    .bcipottery-carousel-banner-box{ height: 100%!important;}
    .pop-box .add-order-quentity-box{height: 320px!important ;overflow-y: auto;}
    .pop-box{ width: 100%!important;}
    .main-carosel-banner{
      .owl-carousel.owl-drag{
        .owl-item{}
      }

    }
    .bcipottery-wraper{min-height:calc(100vh - 390px);}
    .listing-wrap{min-height:700px; padding-top: 10px;}
    .order-details-top{min-height:650px;}
  .cart-wrap{min-height:650px;}
  .my-account-wrap{min-height:650px;}
  .custom-all-page-min-height{min-height: 600px;}
  .order-details-top .right-section .color-palate-tab-sec{flex-wrap: wrap;}
  .order-details-top .right-section .color-palate-tab-sec a.color-tab{ width: 100%; margin-bottom: 8px;}
  .order-details-top .right-section .color-palate-tab-sec a.color-tab:last-child{ border: #DDDDDD 1px solid;}
  .order-details-top .right-section .color-palate-tab-sec a.color-tab.active:last-child{border: #174e6d 1px solid;}
  .order-details-top .right-section .color-palate-tab-sec a.color-tab:first-child{border: #DDDDDD 1px solid;}
  .order-details-top .right-section .value-row {
    padding: 0;
    flex-wrap: wrap;
}
}

@media only screen and (max-width: 360px){
  .main-banner .banner-inner-sec .banner-text-area h1 {font-size: 32px;}
  .login-body .login-box .login-box-inner button {font-size: 11px;}
  .product-cart-wrap .product-cart-row .left-img-block {width: 60px;height: 60px;}
  .address-wrap .address-box .edit-sec span.defult-address{font-size: 9px;}


}

@media screen and (max-width: 580px){
  .mega-search .mega-search-innerwrap {
    width: auto !important;
  }
}

