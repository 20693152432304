$projet-common-font:"Poppins", sans-serif;
$project-heading-font:"Playfair Display", serif;
/* project font size */
$font-style-1:64px;
$font-style-2:48px;
$font-style-3:40px;
$font-style-4:32px;
$font-style-5:24px;
$body-sub-heading:30px;
/* ---- */
$common-font15:15px;
$common-font14:14px;
/* project font size */
/* font style */

/* font style */
/* project color palate */
$primary-color:#174E6D;
$primary-color-2:#76949F;
$primary-color-3:#BFCBD0;
$base-color:#BDAD9EDD;
$base-color-2:#9D876D;
$base-color-3:#EEECE9;
$base-color-4:#333333;
$text-fade-color:#999999;
$base-color-5:#f00606;
/* project color palate */

/* project box shadow */
$common-shadow:0px 0px 10px #0000000D;
$btn-radius:6px;
$common-radius:6px;
/* project box shadow */

 /* font style */
 @mixin common-bdy-font{font-size: $common-font15; color:$base-color-4; font-family: $projet-common-font; font-weight: 400; letter-spacing: 0.8px; }
 @mixin heading-bdy-font{font-size: $common-font15; color:$base-color-4; font-family:$project-heading-font; font-weight: 400; letter-spacing: 0.6px; }
 /* font style */

/* heading style */
@mixin heading-64{ font-size: $font-style-1; font-family: $project-heading-font;}
@mixin heading-48{ font-size: $font-style-2; font-family: $project-heading-font;}
@mixin heading-40{ font-size: $font-style-3; font-family: $project-heading-font;}
@mixin heading-36{ font-size: $font-style-4; font-family: $project-heading-font;}
@mixin heading-32{ font-size: $font-style-5; font-family: $project-heading-font;}
@mixin heading-24{ font-size: $font-style-6; font-family: $project-heading-font;}
/* heading style */

/* project button style */
@mixin btn-style-white{ padding:0 20px; margin: 0; height: 50px; font-size:16px; font-family: $projet-common-font; text-transform:uppercase;
color:$primary-color; background-color: #fff; -webkit-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
-moz-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29); cursor: pointer; border: 0; outline: none; display: flex; align-items: center; justify-content: center;
line-height: 48px; letter-spacing: 2px; font-weight: 600;
}

@mixin btn-style-blue{ padding:0 20px; margin: 0; height: 50px; font-size:16px; font-family: $projet-common-font; text-transform:uppercase;
    color:#fff; background-color:$primary-color; -webkit-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
    -moz-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
    box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29); cursor: pointer; border: 0; outline: none; display: flex; align-items: center; justify-content: center;
    line-height: 48px; font-weight: 600;
}
@mixin btn-style-yellow{ padding:0 20px; margin: 0; height: 50px; font-size:16px; font-family: $projet-common-font; text-transform:uppercase;
    color:#fff; background-color:#9D876D; -webkit-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
    -moz-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
    box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29); cursor: pointer; border: 0; outline: none; display: flex; align-items: center; justify-content: center;
    line-height: 48px; font-weight: 600;
}
@mixin white-line-button{ padding:0 20px; margin: 0; height: 48px; font-family: $projet-common-font; text-transform:uppercase;
    background-color:#fff; -webkit-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29);
    -moz-box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29); border: #666666 1px solid;
    box-shadow: 0px 1px 14px -1px rgba(0,0,0,0.29); cursor: pointer; outline: none; display: flex; align-items: center; justify-content: center;
    line-height: 46px; font-weight: 600; letter-spacing: 2px;
}
@mixin white-line-button-normal{ padding:5px 14px; margin: 0; height:30px; font-family: $projet-common-font; text-transform:capitalize;
background-color:#fff;  border: #DDDDDD 1px solid; cursor: pointer; outline: none; display: flex; align-items: center; justify-content: center;
line-height: 30px; font-weight:500; letter-spacing: 0; color: #174D6D; }

/* project button style */


/* container */
@mixin center-container{ padding: 0; margin: 0 auto; width: 1325px!important;}
@mixin full-container{ padding: 0; margin: 0 auto; width:100%;}
// @mixin container-full{ padding: 0; margin: 0; width: 100%;}


/* col */
@mixin common-row{ padding: 0; margin: 0; width: 100%;}
@mixin col-four{ width: 100%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}
@mixin col-three{ width: 75%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}
@mixin col-tow{ width: 50%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}
@mixin col-one{ width: 25%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}
@mixin col-single{ width:33.3%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}
@mixin col-one-thrd{width: 65%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}
@mixin col-thrd-one{width:35%; padding-left:15px; padding-right: 15px; box-sizing: border-box;}

@mixin box-head{}
@mixin from-box{padding:4px 20px 18px 20px; box-sizing: border-box; font-size:$font-standard-o1; color:$base-color-regular;}
@mixin cmn-btn{}
@mixin cmn-btn-sml{font-weight: $base-font-weight;}
@mixin btnblue-line{ }
@mixin v-row{
}
@mixin col-sm100{ width: 100%;}
/* col */


/* custom padding & margin */
.pt-30{padding-top: 30px!important;}
.mb-60{margin-bottom: 60px!important;}
/* custom padding & margin */

/* project heading style */
@mixin project-sub-heading{ font-family: $project-heading-font; color: $primary-color; font-size: $body-heading;
        font-weight:$base-light-font-weight; line-height: 46px;}


@mixin link-text{
    display: inline-flex;
    margin: 12px 0 0 0;
    align-items: center;
    color: #22332C;
    font-family: "helvetica_neueregular", sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}
/* project heading style */

/*  loder */
.loderbody{ padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(0,0,0,0.6);
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
 img{width:100px; height: auto; max-height:100px; top:30%}
}
.loderbody.nobg{background-color:transparent;}
/*  loder */

