@font-face {
    font-family: 'icomoon';
    src:  url('../icon-fonts/icomoon.eot?faasjf');
    src:  url('../icon-fonts/icomoon.eot?faasjf#iefix') format('embedded-opentype'),
      url('../icon-fonts/icomoon.ttf?faasjf') format('truetype'),
      url('../icon-fonts/icomoon.woff?faasjf') format('woff'),
      url('../icon-fonts/icomoon.svg?faasjf#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon',sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-user:before {
    content: "\e900";
  }
  .icon-search:before {
    content: "\e901";
  }
  .icon-cart:before {
    content: "\e902";
  }
  .icon-call:before {
    content: "\e903";
  }
  .icon-cross:before {
    content: "\e904";
  }
  .icon-facebook:before {
    content: "\e905";
  }
  .icon-icon-tick:before {
    content: "\e907";
  }
  .icon-heart:before {
    content: "\e906";
  }
  .icon-minus-sign:before {
    content: "\e908";
  }
  .icon-plus:before {
    content: "\e909";
  }
  .icon-down-arrow:before {
    content: "\e90a";
  }
  .icon-up-arrow:before {
    content: "\e90b";
  }
  .icon-hambargar-mobile:before {
    content: "\e90c";
  }
  .icon-nex:before {
    content: "\e90d";
  }
  .icon-next:before {
    content: "\e90e";
  }
  .icon-filter:before {
    content: "\e90f";
  }
  .icon-shorts:before {
    content: "\e910";
  }
  .icon-star:before {
    content: "\e911";
  }
  .icon-tick-round:before {
    content: "\e912";
  }
  .icon-container-ship:before {
    content: "\e913";
  }
  .icon-subscribtion:before {
    content: "\e914";
  }
  .icon-water:before {
    content: "\e915";
  }
  .icon-feathers:before {
    content: "\e916";
  }
  .icon-plant:before {
    content: "\e917";
  }
  .icon-fire:before {
    content: "\e918";
  }
  .icon-frost-resistant:before {
    content: "\e919";
  }
  .icon-settings:before {
    content: "\e91a";
  }
  .icon-hand-made:before {
    content: "\e91b";
  }
  .icon-youtube:before {
    content: "\e91c";
  }
  .icon-instagram:before {
    content: "\e91d";
  }
  .icon-location:before {
    content: "\e91e";
  }
  .icon-saucer:before {
    content: "\e91f";
  }
  .icon-pad_1:before {
    content: "\e920";
  }
  .icon-wall-plug:before {
    content: "\e921";
  }
  .icon-paint-brush:before {
    content: "\e923";
  }
  .icon-snack:before {
    content: "\e922";
  }
  .icon-linkedin:before {
    content: "\e924";
  }
  .icon-info:before {
    content: "\e925";
  }
  .icon-Attached-Saucer-Icon:before {
    content: "\e926";
  }
  .icon-Glazed-Finish-Icon:before {
    content: "\e927";
  }
  .icon-Drainage-Icon_1:before {
    content: "\e928";
  }
  .icon-Hand-Painted-Icon_1:before {
    content: "\e929";
  }
  .icon-logout:before {
    content: "\e92a";
  }
  .icon-line-heart:before {
    content: "\e92b";
  }
  .icon-trash-bin:before {
    content: "\e92c";
  }
/* ////// */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
// font-family: "Playfair Display", serif;

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// font-family: "Poppins", sans-serif;
